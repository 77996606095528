import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import Moment from 'react-moment';
import JSONPretty from 'react-json-pretty';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Link } from 'gatsby';

import PlayerContext from '../../context/PlayerContext';
import ThemeContext from '../../context/ThemeContext';
import ButtonOutline from '../../components/ButtonOutline';
import RouterTracker from '../../components/RouterTracker';
import SvgPlay from '../../icons/Play';
import SvgAdd from '../../icons/Add';
import LoginButton from '../../components/LoginButton';
import LogoutButton from '../../components/LogoutButton';
import SvgClose from '../../icons/Close';
import SvgPause from '../../icons/Pause';

const ArchiveList = styled.div(
  (props) => `
  width: 100%;
  margin-bottom: ${props.theme.size.base * 4}px;
  .archive-item {
    display: flex;
    flex-direction: row;
    align-items: space-between;
    margin-bottom: ${props.theme.size.base * 2}px;
    padding-bottom: ${props.theme.size.base * 2}px;
    border-bottom: 1px solid ${props.theme.color.secondary[30]};
    &:last-of-type {
      border-bottom: none;
    }
  }
  .archive-info {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .archive-image {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${props.theme.size.base * 0.5}px;
    width: ${props.theme.size.base * 9}px;
    height: ${props.theme.size.base * 9}px;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .archive-time {
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    color: ${props.theme.color.foreground[80]};
    transition: color 450ms ease;
  }
  .archive-title {
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    font-weight: 600;
    color: ${props.theme.color.primary[50]};
    transition: color 450ms ease;
    a:visted,
    a {
      color: ${props.theme.color.primary[50]};
      transition: color 450ms ease;
      &:hover {
        color: ${props.theme.color.primary[65]};
      }
    }
  }
  .archive-djs {
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    color: ${props.theme.color.secondary[50]};
    transition: color 450ms ease;
  }
  .archive-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
  }

  `
);

const ArchiveStyles = styled.div(
  (props) => `
  padding: ${props.theme.size.base * 13}px ${props.theme.size.base * 4}px ${
    props.theme.size.base * 4
  }px;
  .body {
    position: relative;
    z-index: 500;
    max-width: ${props.theme.size.base * 128}px;
    margin: 0 auto;
  }
  .data {
    background: ${props.theme.color.background[90]};
    margin-bottom: ${props.theme.size.base * 4}px;
  }
  ${
    (props.breakpoints.md || props.breakpoints.lg || props.breakpoints.xl) &&
    `
    padding: ${props.theme.size.base * 4}px;

  `
  };
`
);

const Index = (props) => {
  const [archives, setArchives] = useState([]);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const breakpoints = useBreakpoint();

  const getArchives = async () => {
    // const endpoint = 'http://localhost:443/archives';
    const endpoint = 'https://kuaa-chat-api-2nftz.ondigitalocean.app/archives';
    try {
      const _archives = await axios.get(endpoint);
      setArchives(_archives.data); // set State
    } catch (err) {
      console.error(err.message);
    }
  };

  // Set update loop
  useEffect(() => {
    getArchives();
  }, []);

  const isInTracklist = (track) => {
    // check if track is in props.context.tracklistq
    return props.context.tracklist.some(
      (tracklist) => tracklist.id === track.id
    );
  };

  const isActiveTrack = (track) => {
    // check if track is props.context.currentTrack
    return props.context.currentTrack.id === track.id;
  };

  const calendarStrings = {
    lastDay: '[Yesterday], LT',
    sameDay: '[Today], LT',
    nextDay: '[Tomorrow], LT',
    lastWeek: '[last] dddd, LT',
    nextWeek: 'dddd, LT',
    sameElse: 'LL, LT',
  };

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <ArchiveStyles theme={theme} breakpoints={breakpoints}>
          <section className='body typesetter'>
            {/* <Link to='/settings'>Settings</Link> */}
            <RouterTracker />
            {archives && (
              <div>
                <h2 className='section-title'>Archives</h2>
                <div className='scroll-x'>
                  <ArchiveList
                    className='data-table'
                    theme={theme}
                    breakpoints={breakpoints}
                  >
                    {archives.map((archive, index) => (
                      <div className='archive-item' key={index}>
                        <div className='archive-info'>
                          <div className='archive-image'>
                            {isActiveTrack(archive) && props.context.playing ? (
                              <ButtonOutline
                                active={true}
                                square={true}
                                shape={4}
                                onClick={() => props.context.setPlaying(false)}
                                label='Pause Audio'
                              >
                                <SvgPause />
                              </ButtonOutline>
                            ) : (
                              <ButtonOutline
                                square={true}
                                shape={4}
                                onClick={() =>
                                  props.context.addToTracklist(
                                    {
                                      id: archive.id,
                                      name: archive.show.title,
                                      url: props.context.mediaLink(
                                        archive.file
                                      ),
                                    },
                                    true
                                  )
                                }
                                label={`Play ${archive.show.title} Episode`}
                              >
                                <SvgPlay />
                              </ButtonOutline>
                            )}
                          </div>
                          <div>
                            <Link
                              class='archive-title'
                              to={`/show?id=${archive.show.id}`}
                            >
                              {archive.show.title}
                            </Link>
                            <div class='archive-djs'>
                              {archive.djs.map((dj, index) => (
                                <span key={index}>
                                  {dj.name}
                                  {index < archive.djs.length - 1 && (
                                    <span>&nbsp;&nbsp;</span>
                                  )}
                                </span>
                              ))}
                            </div>
                            <Moment calendar={calendarStrings}>
                              {archive.start}
                            </Moment>
                          </div>
                        </div>

                        <div class='archive-actions'>
                          {isInTracklist(archive) ? (
                            <ButtonOutline
                              active={true}
                              shape={4}
                              color='secondary'
                              size={-2}
                              onClick={() =>
                                props.context.removeFromTracklist(archive)
                              }
                              label={`Remove ${archive.show.title} From Queue`}
                            >
                              <SvgClose
                                style={{
                                  marginRight: theme.size.base * 1,
                                }}
                              />
                              In Queue
                            </ButtonOutline>
                          ) : (
                            <ButtonOutline
                              shape={4}
                              color='secondary'
                              square={breakpoints.xs || breakpoints.sm}
                              size={-2}
                              onClick={() =>
                                props.context.addToTracklist(
                                  {
                                    id: archive.id,
                                    name: archive.show.title,
                                    url: props.context.mediaLink(archive.file),
                                  },
                                  false
                                )
                              }
                              label={`Add ${archive.show.title} to Queue`}
                            >
                              <SvgAdd
                                style={{
                                  marginRight:
                                    breakpoints.xs || breakpoints.sm
                                      ? 0
                                      : theme.size.base * 1,
                                }}
                              />
                              <span
                                className={
                                  (breakpoints.xs || breakpoints.sm) &&
                                  'visually-hidden'
                                }
                              >
                                Add to Queue
                              </span>
                            </ButtonOutline>
                          )}
                        </div>
                      </div>
                    ))}
                  </ArchiveList>
                </div>
              </div>
            )}

            {1 == 2 && (
              <>
                {isAuthenticated ? <LogoutButton /> : <LoginButton />}

                {user && <JSONPretty data={user} />}
                {props.context.currentTrack && (
                  <JSONPretty data={props.context.currentTrack} />
                )}
                {props.context.tracklist &&
                  props.context.tracklist.length > 0 && (
                    <JSONPretty data={props.context.tracklist} />
                  )}
              </>
            )}
          </section>
        </ArchiveStyles>
      )}
    </ThemeContext.Consumer>
  );
};

const IndexTemplate = (props) => {
  return (
    <PlayerContext.Consumer>
      {(context) => <Index context={context} {...props} />}
    </PlayerContext.Consumer>
  );
};

export default IndexTemplate;
