import { themeDefault, themeDefaultDark } from '../themeDefault';
import { createFontSize, createLineHeight } from './createFontSize';
var chroma = require('chroma-js');

export function generateTheme(colors, image, release) {
  const themeLight = {
    ...themeDefault,
    themeName: release,
    themeImage: image,
    color: {
      ...themeDefault.color,
      primary: [chroma(colors[0].color).set('hsl.l', 0.45).hex()],
      secondary: [chroma(colors[3].color).set('hsl.l', 0.4).hex()],
      tertiary: [chroma(colors[4].color).set('hsl.l', 0.35).hex()],
      accent: [
        chroma(colors[5].color).set('hsl.s', 0.5).set('hsl.l', 0.5).hex(),
      ],
      grayscale: [
        chroma(colors[4].color).set('hsl.l', 0.05).hex(),
        chroma(colors[4].color).set('hsl.l', 1).hex(),
      ],
      background: [
        chroma(colors[4].color).set('hsl.l', 0.85).hex(),
        chroma(colors[4].color).set('hsl.l', 1).hex(),
      ],
      foreground: [
        chroma(colors[4].color).set('hsl.l', 0.5).set('hsl.s', 0.05).hex(),
        chroma(colors[4].color).set('hsl.l', 0.2).set('hsl.s', 0.1).hex(),
      ],
    },
  };

  const themeDark = {
    ...themeDefaultDark,
    themeName: `${release} - Dark`,
    themeImage: image,
    color: {
      primary: [chroma(colors[0].color).set('hsl.l', 0.5).hex()],
      secondary: [chroma(colors[3].color).set('hsl.l', 0.45).hex()],
      tertiary: [chroma(colors[4].color).set('hsl.l', 0.4).hex()],
      accent: [
        chroma(colors[5].color).set('hsl.s', 0.5).set('hsl.l', 0.5).hex(),
      ],
      grayscale: [
        chroma(colors[4].color).set('hsl.l', 0.05).hex(),
        chroma(colors[4].color).set('hsl.l', 1).hex(),
      ],
      background: [
        chroma(colors[4].color).set('hsl.l', 0.05).hex(),
        chroma(colors[4].color).set('hsl.l', 0.2).hex(),
      ],
      foreground: [
        chroma(colors[4].color).set('hsl.l', 0.5).set('hsl.s', 0.1).hex(),
        chroma(colors[4].color).set('hsl.l', 0.9).set('hsl.s', 0.2).hex(),
      ],
    },
  };
  return { themeLight: themeLight, themeDark: themeDark };
}
