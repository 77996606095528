import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import placeHolderImage from '../img/record.jpg';
import Badge from '../components/Badge';

import { formatRelative, lightFormat } from 'date-fns';

import axios from 'axios';

const DjslistStyles = styled.div(
  (props) => `
  .djlist {
    display: flex;
    flex-wrap: wrap;
    margin: ${props.theme.size.base * -1}px;
    .djlist-item {
      padding: ${props.theme.size.base * 1}px;
      display: flex;
      flex-direction: column;
      flex: 1 0 calc(100% - ${props.theme.size.base * 2}px);
      text-decoration: none;
      ${
        (props.breakpoints.lg || props.breakpoints.xl) &&
        `
        flex: 1 0 calc(calc(100% - ${props.theme.size.base * 4}px) / 2);
        `
      };
    }
  }
  .djlist-card {
    flex-grow: 1;
    ${
      props.theme.dark
        ? `
        background: ${props.theme.color.background[15]};
        background: linear-gradient(
          20deg,
          ${props.theme.color.background[65]},
          ${props.theme.color.background[45]}
        );
      `
        : `
      background: ${props.theme.color.background[90]};
      background: linear-gradient(
        20deg,
        ${props.theme.color.background[95]},
        ${props.theme.color.background[85]}
      );
      `
    };
    border-radius: ${props.theme.size.base}px;
    padding: ${props.theme.size.base * 3}px;
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    .dj-header {
      display: flex;
    }
    .dj-image {
      flex-shrink: 0;
      margin-bottom: ${props.theme.size.base * 1}px;
      margin-right: ${props.theme.size.base * 2}px;
      width: ${props.theme.size.base * 6}px;
      height: ${props.theme.size.base * 6}px;
      border-radius: ${props.theme.size.base * 5}px;
      ${
        (props.breakpoints.md ||
          props.breakpoints.lg ||
          props.breakpoints.xl) &&
        `
        margin-bottom: -${props.theme.size.base * 6}px;
        width: ${props.theme.size.base * 8}px;
        height: ${props.theme.size.base * 8}px;
      `
      };
    }
  }
  .dj-social {
    font-size: ${props.theme.size.fontSize[1]};
    line-height: ${props.theme.size.lineHeight[1]};
    margin-bottom: ${props.theme.size.base * 0.5}px;
    a {
      margin-right: ${props.theme.size.base * 2}px;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      color: ${
        props.theme.dark
          ? props.theme.color.tertiary[60]
          : props.theme.color.tertiary[40]
      };
      transition: color 450ms ease;
      text-decoration: none;
      &:hover {
        color: ${
          props.theme.dark
            ? props.theme.color.primary[60]
            : props.theme.color.primary[40]
        };
      }
    }
  }
  .dj-name {
    font-size: ${props.theme.size.fontSize[4]};
    line-height: ${props.theme.size.lineHeight[4]};
    font-weight: 300;
    color: ${props.theme.color.foreground[100]};
    transition: color 450ms ease;
    margin-bottom: ${props.theme.size.base * 0.5}px;
    a {
      color: ${props.theme.color.foreground[100]};
      transition: color 450ms ease;
      text-decoration: none;
      &:hover {
        color: ${props.theme.color.tertiary[60]};
      }
    }
  }
  .dj-bio {
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    color: ${props.theme.color.foreground[40]};
    transition: color 1200ms ease;
    margin-bottom: ${props.theme.size.base * 0.5}px;
    font-weight: 200;
    p {
      margin: 0;
      font-size: ${props.theme.size.fontSize[2]};
      line-height: ${props.theme.size.lineHeight[2]};
    }
  }
`
);

const ShowsList = styled.div(
  (props) => `
  margin:
    0
    ${props.theme.size.base * -1}px
    ${props.theme.size.base * -1}px;
  ${
    (props.breakpoints.md || props.breakpoints.lg || props.breakpoints.xl) &&
    `
    margin-left: ${props.theme.size.base * 9}px;
  `
  };
  .showlist-item {
    color: ${props.theme.color.foreground[40]};
    transition: color 1200ms ease;
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    padding: ${props.theme.size.base * 1}px;
    display: flex;
    a,
    a:link,
    a:visited {
      text-decoration: none;
      color: ${
        props.theme.dark
          ? props.theme.color.primary[65]
          : props.theme.color.primary[30]
      };
      transition: color 450ms ease;
      &:hover {
        color: ${
          props.theme.dark
            ? props.theme.color.secondary[75]
            : props.theme.color.secondary[45]
        };
      }
    }
  }
  .showlist-actions {
    margin: ${props.theme.size.base * 1}px;
  }
  .show-image {
    flex-shrink: 0;
    margin-right: ${props.theme.size.base * 2}px;
    width: ${props.theme.size.base * 6}px;
    height: ${props.theme.size.base * 6}px;
    border-radius: ${props.theme.size.base}px;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .on-air {
    display: inline-block;
  }
  .show-time {
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-size: ${props.theme.size.fontSize[1]};
    line-height: ${props.theme.size.lineHeight[1]};
  }
`
);

const Djslist = (props) => {
  const [posts, setPosts] = useState([]);

  const getShow = async () => {
    try {
      const userPosts = await axios.get(
        `https://kuaa-api-7xgcl.ondigitalocean.app/djs/55`
      );
      setPosts(userPosts.data); // set State
    } catch (err) {
      console.error(err.message);
    }
  };

  // Set update loop
  useEffect(() => {
    getShow();
  }, []);

  function filterShows(shows) {
    let _shows = [];
    shows.map((show, index) => {
      if (new Date(show.end) > new Date()) {
        _shows.push(show);
      }
    });
    return _shows;
  }

  function isLive(start, end) {
    var dateNow = new Date();
    var dateStart = new Date(start);
    var dateEnd = new Date(end);
    return dateNow > dateStart && dateEnd > dateNow;
  }

  function createMarkup(html) {
    return { __html: html };
  }
  return (
    <DjslistStyles theme={props.theme} breakpoints={props.breakpoints}>
      <div className='djlist'>
        {posts &&
          posts.map(
            (post, index) =>
              post.shows.length > 0 && (
                <div key={index} className='djlist-item'>
                  <div className='djlist-card'>
                    <div className='dj-header'>
                      {post.image ? (
                        <img
                          width='170'
                          src={post.image}
                          alt={`${post.name}`}
                          className='dj-image'
                        />
                      ) : (
                        <img
                          width='170'
                          src={placeHolderImage}
                          alt={`${post.name}`}
                          className='dj-image'
                        />
                      )}
                      <div>
                        <div className='dj-name'>{post.name}</div>
                        {(post.website || post.email) && (
                          <div className='dj-social'>
                            {post.website && (
                              <a
                                href={post.website}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                Website
                              </a>
                            )}
                            {post.email && (
                              <a
                                href={`mailto:${post.email}`}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                Email
                              </a>
                            )}
                          </div>
                        )}
                        {post.bio && (
                          <div
                            className='dj-bio'
                            dangerouslySetInnerHTML={createMarkup(post.bio)}
                          ></div>
                        )}
                      </div>
                    </div>
                    {post.shows && (
                      <ShowsList
                        theme={props.theme}
                        breakpoints={props.breakpoints}
                      >
                        {post.shows.slice(0, 3).map(
                          (show, index) =>
                            show.title && (
                              <div key={index} className='showlist-item'>
                                <Link to={`/show?id=${show.id}`}>
                                  <div className='show-image'>
                                    {show.image ? (
                                      <img
                                        src={show.image}
                                        alt={`${show.title}`}
                                      />
                                    ) : (
                                      <img
                                        src={placeHolderImage}
                                        alt={`${show.title}`}
                                      />
                                    )}
                                  </div>
                                </Link>
                                <div>
                                  <Link to={`/show?id=${show.id}`}>
                                    {show.title}
                                  </Link>
                                  <div>
                                    {isLive(show.start, show.end) ? (
                                      <Badge color='accent' className='on-air'>
                                        On Air
                                      </Badge>
                                    ) : (
                                      <div className='show-time'>
                                        {formatRelative(
                                          new Date(show.start),
                                          new Date()
                                        )}{' '}
                                        -{' '}
                                        {lightFormat(
                                          new Date(show.end),
                                          'h:mm a'
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                        {/* {filterShows(post.shows).length > 3 && (
                        <div className='showlist-actions'>
                          <Button size={-2} shape={4} color='tertiary'>
                            View More
                          </Button>
                        </div>
                      )} */}
                      </ShowsList>
                    )}
                  </div>
                </div>
              )
          )}
      </div>
    </DjslistStyles>
  );
};

export default Djslist;
