import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { useLocalStorage } from './userLocalStorage';
import { v4 as uuidv4 } from 'uuid';
import { number } from 'prop-types';

const IPData = require('ipdata').default;

const ipdata = new IPData(
  '827a15a0b0ceb041892b18574d56daba85e94c5dc61cf07d69bf1d21'
);

const defaultState = {
  playing: false,
};

const PlayerContext = React.createContext(defaultState);

const PlayerProvider = ({ url, children }) => {
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.8);
  const [showVolume, setShowVolume] = useState(false);
  const [mute, setMute] = useState(false);
  const [showPlaylist, setShowPlaylist] = useState(false);
  const [miniNav, setMiniNav] = useState(true);
  const [miniPlayer, setMiniPlayer] = useState(true);
  const [firstVisit, setFirstVisit] = useState(true);

  const [tracklist, setTracklist] = useState([
    {
      id: 'kuaa-live',
      name: 'Live Stream',
      url: 'https://stream.xmission.com/kuaa',
      active: true,
    },
  ]);
  const [currentTrack, setCurrentTrack] = useState({
    id: 'kuaa-live',
    name: 'Live Stream',
    url: 'https://stream.xmission.com/kuaa',
  });

  const [users, setUsers] = useState(null);
  const [test, setTest] = useState(null);
  const [likesong, setTest2] = useState(null);
  const [mylikes, setMyLikes] = useState(null);
  const [socketUID, setSocketUID] = useState(null);

  const [userID, setUserID] = useLocalStorage('user', null);

  // const SOCKET_SERVER_URL = 'http://localhost:443';
  const SOCKET_SERVER_URL = 'https://kuaa-chat-api-2nftz.ondigitalocean.app';
  const socketRef = useRef();

  const getUserID = async () => {
    if (userID === null) {
      const newUserID = uuidv4();
      setUserID(newUserID);
      return newUserID;
    } else {
      return userID;
    }
  };

  useEffect(() => {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: 'Unforgettable',
        artist: 'Nat King Cole',
        album: 'The Ultimate Collection (Remastered)',
        artwork: [
          {
            src: 'https://dummyimage.com/96x96',
            sizes: '96x96',
            type: 'image/png',
          },
          {
            src: 'https://dummyimage.com/128x128',
            sizes: '128x128',
            type: 'image/png',
          },
          {
            src: 'https://dummyimage.com/192x192',
            sizes: '192x192',
            type: 'image/png',
          },
          {
            src: 'https://dummyimage.com/256x256',
            sizes: '256x256',
            type: 'image/png',
          },
          {
            src: 'https://dummyimage.com/384x384',
            sizes: '384x384',
            type: 'image/png',
          },
          {
            src: 'https://dummyimage.com/512x512',
            sizes: '512x512',
            type: 'image/png',
          },
        ],
      });

      navigator.mediaSession.setActionHandler('play', function () {
        console.log('play');
      });
      navigator.mediaSession.setActionHandler('pause', function () {
        console.log('pause');
      });
      navigator.mediaSession.setActionHandler('stop', function () {
        console.log('stop');
      });
      navigator.mediaSession.setActionHandler('previoustrack', function () {
        console.log('previoustrack');
      });
      navigator.mediaSession.setActionHandler('nexttrack', function () {
        console.log('nexttrack');
      });
    }
  }, []);

  useEffect(() => {
    getUserID().then((userID) => {
      ipdata
        .lookup()
        .then(function (info) {
          socketRef.current = io(SOCKET_SERVER_URL);
          socketRef.current.on('mylikes', (data) => {
            setMyLikes(data);
          });

          socketRef.current.on('connect', () => {
            setSocketUID(socketRef.current.id);
            socketRef.current.emit('connected', {
              id: userID,
              city: info.city,
              region: info.region,
              country: info.country_name,
              latitude: info.latitude,
              longitude: info.longitude,
            });
          });

          socketRef.current.on('FromAPI', (data) => {
            setUsers(data);
          });
          socketRef.current.on('test', (t) => {
            setTest(t);
          });
          socketRef.current.on('likesong', (t2) => {
            setTest2(t2);
          });
        })
        .catch(function () {
          socketRef.current = io(SOCKET_SERVER_URL);
          socketRef.current.on('mylikes', (data) => {
            setMyLikes(data);
          });
          socketRef.current.on('connect', () => {
            setSocketUID(socketRef.current.id);
            socketRef.current.emit('connected', {
              id: userID,
              city: 'Salt Lake City',
              region: 'Utah',
              country: 'United States',
              latitude: 40.6982,
              longitude: -111.841,
            });
          });

          socketRef.current.on('FromAPI', (data) => {
            setUsers(data);
          });
          socketRef.current.on('test', (t) => {
            setTest(t);
          });
          socketRef.current.on('likesong', (t2) => {
            setTest2(t2);
          });
        });
    });

    return () => socketRef.current?.disconnect();
  }, []);

  const sendLike = (nowPlaying) => {
    socketRef.current.emit('likesong', {
      id: userID,
      nowPlaying,
    });
  };

  function setTrack(track) {
    setCurrentTrack({
      id: track.id,
      name: track.name,
      url: track.url,
    });
    // set track to active in tracklist
    setTracklist((prev) =>
      prev.map((t) => {
        if (t.id === track.id) {
          return { ...t, active: true };
        } else {
          return { ...t, active: false };
        }
      })
    );
    if (track.id !== tracklist[0].id) {
      setShowPlaylist(false);
    }
  }
  function addToTracklist(track, playNow) {
    // Add track to tracklist if not already in tracklist
    if (tracklist.find((t) => t.id === track.id) === undefined) {
      setTracklist((prev) => [...prev, { ...track, active: false }]);
    }
    if (playNow === true) {
      setTrack(track);
      setPlaying(true);
    }
  }
  function removeFromTracklist(track) {
    // if track.id does not equal 'kuaa-live'
    if (track.id !== 'kuaa-live') {
      setTracklist(tracklist.filter((t) => t.id !== track.id));
      // if track is currentTack, get the next track in tracklist
      if (track.id === currentTrack.id) {
        console.log('removeFromTracklist currentTrack');
        playNextTrack();
      }
    }
  }
  function clearTracklist() {
    setTracklist([
      {
        id: 'kuaa-live',
        name: 'Live Stream',
        url: 'https://stream.xmission.com/kuaa',
        active: true,
      },
    ]);
  }

  function playNextTrack() {
    // get the next track in tracklist
    const nextTrack = tracklist.find((t) => t.id === currentTrack.id);
    const nextIndex = tracklist.indexOf(nextTrack) + 1;
    if (nextIndex < tracklist.length) {
      setTrack(tracklist[nextIndex]);
    } else {
      setTrack(tracklist[0]);
    }
  }
  function mediaLink(url) {
    return `https://staging.jessedidthis.com/public/${url}`;
  }

  function getSongLikes(songId) {
    if (!songId) {
      return 0;
    }
    if (!likesong) {
      return 0;
    }
    // filter likes where nowPlaying.SongID === songId
    const songLikes = likesong.filter(
      (like) => Number(like.SongID ? like.SongID : 0) === Number(songId)
    );
    return songLikes.length;
  }
  function doILikeThisSong(songId) {
    if (!songId) {
      return false;
    }
    if (!mylikes) {
      return false;
    }
    if (!userID) {
      return false;
    }
    const songLikes = mylikes.filter(
      (like) => Number(like.SongID) === Number(songId)
    );
    return songLikes.length > 0;
  }

  return (
    <PlayerContext.Provider
      value={{
        playing,
        volume,
        showVolume,
        mute,
        showPlaylist,
        miniNav,
        miniPlayer,
        firstVisit,
        setPlaying,
        setVolume,
        setShowVolume,
        setMute,
        setShowPlaylist,
        setMiniNav,
        setMiniPlayer,
        setFirstVisit,
        currentTrack,
        playNextTrack,
        mediaLink,
        tracklist,
        setTrack,
        addToTracklist,
        removeFromTracklist,
        clearTracklist,
        users,
        test,
        likesong,
        mylikes,
        socketUID,
        sendLike,
        getSongLikes,
        doILikeThisSong,
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
};

export default PlayerContext;

export { PlayerProvider };
