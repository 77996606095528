import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import axios from 'axios';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import {
  formatRelative,
  lightFormat,
  format,
  millisecondsToMinutes,
  addDays,
} from 'date-fns';

import ThemeContext from '../../context/ThemeContext';
import RouterTracker from '../../components/RouterTracker';
import Badge from '../../components/Badge';

import placeHolderImage from '../../img/record.jpg';
import JSONPretty from 'react-json-pretty';

var JSONPrettyMon = require('react-json-pretty/dist/monikai');

const scaleMultiplier = 1.1;

const TimelineContainerStyles = styled.div(
  (props) => `
    position: relative;
    top: ${props.theme.size.base * 18}px;
    .title {
      position: relative;
      z-index: 1220;
      left: ${props.theme.size.base * 14}px;
    }
  `
);

const TimelineNow = styled.div(
  (props) => `
    position: absolute;
    height: 1px;
    left: ${props.theme.size.base * 2}px;
    right: ${props.theme.size.base * 2}px;
    background: ${props.theme.color.accent[50]};
    // &:after {
    //   opacity: 0.3;
    //   content: '';
    //   position: absolute;
    //   height: 1px;
    //   left: ${props.theme.size.base * 2}px;
    //   right: ${props.theme.size.base * 2}px;
    //   z-index: 1200;
    //   background: ${props.theme.color.accent[50]};
    // }
    .date {
      position: absolute;
      z-index: 1220;
      top: 50%;
      transform: translate(0, -50%);
      display: inline-block;
    }
  `
);

const TimelineStyles = styled.div(
  (props) => `
    position: absolute;
    left: 0;
    right: 0;
    .day {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: ${scaleMultiplier * 1440}px;
      display: flex;
      flex-direction: column;
      .date {
        position: absolute;
        top: calc(-${props.theme.size.base * 3}px - 2px);
        left: 0;
        white-space: nowrap;
        text-transform: uppercase;
        letter-spacing: 0.03em;
        padding-left: ${props.theme.size.base * 2}px;
        font-weight: 600;
        font-size: ${props.theme.size.fontSize[0]};
        line-height: ${props.theme.size.base * 3}px;
        line-height: 30px;
        display: inline-block;
        color:  ${props.theme.color.secondary[65]};
      }
      &:after {
        position: absolute;
        bottom: 0;
        height: 2px;
        width: 100%;
        content: '';
        background: ${props.theme.color.secondary[30]};
      }
      .hour {
        position: relative;
        flex: 0 0 calc(100% / 24);
        span {
          display: block;
          padding-left: ${props.theme.size.base * 2}px;
          text-transform: uppercase;
          letter-spacing: 0.03em;
          font-size: ${props.theme.size.fontSize[0]};
          line-height: ${props.theme.size.base * 3}px;
          color: ${props.theme.color.foreground[40]};
        }
        &:after {
          opacity: 0.5;
          position: absolute;
          bottom: 0;
          left: ${props.theme.size.base * 2}px;
          right: ${props.theme.size.base * 2}px;
          height: 1px;
          content: '';
          background: ${props.theme.color.secondary[50]};
        }
      }
    }
  `
);

const ShowsStyles = styled.div(
  (props) => `
    position: relative;
    max-width: ${props.theme.size.base * 64}px;
    padding: 0 ${props.theme.size.base * 12}px;
    margin: 0 auto;
    height: ${scaleMultiplier * 1440 * 7}px;
  `
);

const ShowStyles = styled.div(
  (props) => `
    position: absolute;
    display: flex;
    left: ${props.theme.size.base * 12}px;
    right: ${props.theme.size.base * 2}px;
    &:first-child:before {
      content: '';
      position: absolute;
      bottom: calc(100% + ${props.theme.size.base * 0.5}px);
      left: 0;
      right: 0;
      height: 400px;
      background: ${props.theme.color.background[55]};
      border-radius: ${props.theme.size.base}px; 
    }
    .background {
      display: flex;
      width: 100%;
      flex-grow: 1;
      margin: 
        calc(${props.theme.size.base * 0.5}px + 1px)
        0
        ${props.theme.size.base * 0.5}px;
      ${
        props.theme.dark
          ? `
          background: ${props.theme.color.background[15]};
          background: linear-gradient(
            20deg,
            ${props.theme.color.background[65]},
            ${props.theme.color.background[45]}
          );
        `
          : `
        background: ${props.theme.color.background[90]};
        background: linear-gradient(
          20deg,
          ${props.theme.color.background[95]},
          ${props.theme.color.background[85]}
        );
        `
      };
      border-radius: ${props.theme.size.base}px; 
      overflow: hidden;
    }
    .image-background {
      margin: 
        calc(${props.theme.size.base * 0.5}px + 1px)
        0
        ${props.theme.size.base * 0.5}px;
      border-radius: ${props.theme.size.base}px; 
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        filter: blur(0.25rem) saturate(0%) contrast(200%);
        opacity: 0.1;
      }
    }
    .text {
      flex-grow: 1;
      padding: ${props.theme.size.base * 2}px;
      position: relative;
      z-index: 1225;
      color: ${props.theme.color.foreground[40]};
      font-size: ${props.theme.size.fontSize[2]};
      line-height: ${props.theme.size.lineHeight[2]};
      width: calc(100% -  ${props.theme.size.base * 8}px);
      a {
        text-decoration: none;
      }
    }
    .show-image-container {
      padding: ${props.theme.size.base * 2}px
          0 ${props.theme.size.base * 2}px
          ${props.theme.size.base * 2}px;
      ${props.theme.size.base * 2}px 0;
      flex-shrink: 0;
      width: ${props.theme.size.base * 6}px;
      z-index: 1225;
      ${
        (props.breakpoints.md ||
          props.breakpoints.lg ||
          props.breakpoints.xl) &&
        `
        width: ${props.theme.size.base * 11}px;
      `
      };
    }
    .show-image {
      width: ${props.theme.size.base * 6}px;
      height: ${props.theme.size.base * 6}px;
      border-radius: ${props.theme.size.base}px;
      overflow: hidden;
      ${
        (props.breakpoints.md ||
          props.breakpoints.lg ||
          props.breakpoints.xl) &&
        `
        width: ${props.theme.size.base * 11}px;
        height: ${props.theme.size.base * 11}px;
      `
      };
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .show-time {
      text-transform: uppercase;
      letter-spacing: 0.03em;
      font-size: ${props.theme.size.fontSize[0]};
      line-height: ${props.theme.size.lineHeight[0]};
    }
    a,
    a:link,
    a:visited {
      color: ${props.theme.color.primary[60]};
      transition: color 450ms ease-in-out;
      &:hover {
        color: ${props.theme.color.secondary[65]};
      }
    }
    .on-air {
      margin-top: ${props.theme.size.base * 0.5}px;
      margin-bottom: ${props.theme.size.base * 1}px;
      ${
        (props.breakpoints.md ||
          props.breakpoints.lg ||
          props.breakpoints.xl) &&
        `
        float: right;
      `
      };
    }
    .dj-list {
      display: flex;
      flex-wrap: wrap;
    }
    .dj-item {
      margin-bottom: ${props.theme.size.base * 1}px;
      margin-right: ${props.theme.size.base * 2}px;
      display: flex;
      align-items: center;
      ${
        props.breakpoints.xs &&
        `
        white-space: nowrap;
        margin: 0;
      `
      };
    }
    .dj-social,
    .dj-name {
      font-size: ${props.theme.size.fontSize[1]};
      line-height: ${props.theme.size.lineHeight[1]};
      color: ${props.theme.color.foreground[100]};
      ${
        (props.breakpoints.md ||
          props.breakpoints.lg ||
          props.breakpoints.xl) &&
        `
        font-size: ${props.theme.size.fontSize[2]};
        line-height: ${props.theme.size.lineHeight[2]};
      `
      };
    }
    .dj-image {
      flex-shrink: 0;
      margin-right: ${props.theme.size.base * 1}px;
      width: ${props.theme.size.base * 3}px;
      height: ${props.theme.size.base * 3}px;
      border-radius: ${props.theme.size.base * 2}px;
      overflow: hidden;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      ${
        props.breakpoints.xs &&
        `
        display: none;
      `
      };
    }
  `
);

const Schedule = (props) => {
  const breakpoints = useBreakpoint();
  const [posts, setPosts] = useState([]);

  const getShows = async () => {
    try {
      const userPosts = await axios.get(
        'https://kuaa-api-7xgcl.ondigitalocean.app/schedule/200'
      );
      if (!posts[0]) {
        setPosts(userPosts.data); // set State
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const calendarTime = [
    new Date().setHours(0, 0, 0, 0),
    new Date().setHours(1, 0, 0, 0),
    new Date().setHours(2, 0, 0, 0),
    new Date().setHours(3, 0, 0, 0),
    new Date().setHours(4, 0, 0, 0),
    new Date().setHours(5, 0, 0, 0),
    new Date().setHours(6, 0, 0, 0),
    new Date().setHours(7, 0, 0, 0),
    new Date().setHours(8, 0, 0, 0),
    new Date().setHours(9, 0, 0, 0),
    new Date().setHours(10, 0, 0, 0),
    new Date().setHours(11, 0, 0, 0),
    new Date().setHours(12, 0, 0, 0),
    new Date().setHours(13, 0, 0, 0),
    new Date().setHours(14, 0, 0, 0),
    new Date().setHours(15, 0, 0, 0),
    new Date().setHours(16, 0, 0, 0),
    new Date().setHours(17, 0, 0, 0),
    new Date().setHours(18, 0, 0, 0),
    new Date().setHours(19, 0, 0, 0),
    new Date().setHours(20, 0, 0, 0),
    new Date().setHours(21, 0, 0, 0),
    new Date().setHours(22, 0, 0, 0),
    new Date().setHours(23, 0, 0, 0),
  ];
  const calendar = [
    new Date().setHours(0, 0, 0, 0),
    addDays(new Date().setHours(0, 0, 0, 0), 1),
    addDays(new Date().setHours(0, 0, 0, 0), 2),
    addDays(new Date().setHours(0, 0, 0, 0), 3),
    addDays(new Date().setHours(0, 0, 0, 0), 4),
    addDays(new Date().setHours(0, 0, 0, 0), 5),
    addDays(new Date().setHours(0, 0, 0, 0), 6),
    addDays(new Date().setHours(0, 0, 0, 0), 7),
  ];

  // Set update loop
  useEffect(() => {
    getShows();
  }, []);

  function isLive(start, end) {
    var dateNow = new Date();
    var dateStart = new Date(start);
    var dateEnd = new Date(end);
    return dateNow > dateStart && dateEnd > dateNow;
  }

  function createMarkup(html) {
    return { __html: html };
  }

  function calculateToday() {
    var dateNow = new Date();
    var dateToday = new Date().setHours(0, 0, 0, 0);
    var minutesPassed = millisecondsToMinutes(dateNow - dateToday);
    return minutesPassed * scaleMultiplier;
  }

  function calculatePosition(start) {
    var dateNow = new Date();
    var dateStart = new Date(start);
    var minutesPosition = millisecondsToMinutes(dateStart - dateNow);
    return minutesPosition * scaleMultiplier;
  }

  function calculateHeight(start, end) {
    var dateStart = new Date(start);
    var dateEnd = new Date(end);
    var lengthMinutes = millisecondsToMinutes(dateEnd - dateStart);
    return lengthMinutes * scaleMultiplier;
  }
  function calculatePadding(start, end) {
    var dateNow = new Date();
    var dateStart = new Date(start);
    var dateEnd = new Date(end);
    var lengthMinutes = millisecondsToMinutes(dateEnd - dateStart);
    var minutesPosition = millisecondsToMinutes(dateNow - dateStart);
    return Math.min(
      minutesPosition * scaleMultiplier - 35,
      lengthMinutes * scaleMultiplier - 120
    );
  }

  function todayTime() {
    var dateNow = new Date();
    return lightFormat(dateNow, 'h:mm a');
  }

  return (
    <TimelineContainerStyles theme={props.theme}>
      <div className='typesetter'>
        {/* <h1
          className='title'
          style={{ top: posts[0] ? calculatePosition(posts[0].start) : 0 }}
        >
          Schedule
        </h1> */}
        <TimelineNow theme={props.theme}>
          <Badge color='secondary' className='date'>
            {todayTime()}
          </Badge>
        </TimelineNow>
        <TimelineStyles theme={props.theme} style={{ top: -calculateToday() }}>
          {calendar.map((day, index) => (
            <div className='day'>
              <div className='date'>{format(day, 'EEE - L/d')}</div>
              {calendarTime.map((hour, index) => (
                <div className='hour'>
                  <span>{format(hour, 'h:mm a')}</span>
                </div>
              ))}
            </div>
          ))}
        </TimelineStyles>
        {posts && (
          <ShowsStyles theme={props.theme} breakpoints={breakpoints}>
            <RouterTracker />
            {posts.map((post, index) => (
              <ShowStyles
                breakpoints={breakpoints}
                theme={props.theme}
                key={index}
                style={{
                  height: calculateHeight(post.start, post.end),
                  top: calculatePosition(post.start),
                }}
              >
                <div
                  className='background'
                  style={{ paddingTop: calculatePadding(post.start, post.end) }}
                >
                  {/* <div className='image-background'>
                    {post.image ? (
                      <img src={post.image} alt={`${post.name} photo`} />
                    ) : (
                      <img src={placeHolderImage} alt={`${post.name} photo`} />
                    )}
                  </div> */}
                  <div className='show-image-container'>
                    <div className='show-image'>
                      <Link to={`/show?id=${post.id}`}>
                        {post.image ? (
                          <img src={post.image} alt={post.name} />
                        ) : (
                          <img src={placeHolderImage} alt={post.name} />
                        )}
                      </Link>
                    </div>
                  </div>
                  <div className='text'>
                    {isLive(post.start, post.end) && (
                      <Badge className='on-air'>On Air</Badge>
                    )}
                    <div className='show-time'>
                      {lightFormat(new Date(post.start), 'h:mm a')} -{' '}
                      {lightFormat(new Date(post.end), 'h:mm a')}
                    </div>
                    <div className='show-title'>
                      <Link to={`/show?id=${post.id}`}>{post.title}</Link>
                    </div>
                    <div className='dj-list'>
                      {post.djs.map((dj, index) => (
                        <>
                          {dj.website ? (
                            <a
                              href={dj.website}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <div className='dj-item'>
                                <div className='dj-image'>
                                  {dj.image ? (
                                    <img src={dj.image} alt={dj.name} />
                                  ) : (
                                    <img src={placeHolderImage} alt={dj.name} />
                                  )}
                                </div>
                                <div>
                                  <div className='dj-name'>{dj.name}</div>
                                </div>
                              </div>
                            </a>
                          ) : (
                            <div className='dj-item'>
                              <div className='dj-image'>
                                {dj.image ? (
                                  <img src={dj.image} alt={dj.name} />
                                ) : (
                                  <img src={placeHolderImage} alt={dj.name} />
                                )}
                              </div>
                              <div>
                                <div className='dj-name'>{dj.name}</div>
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </ShowStyles>
            ))}
          </ShowsStyles>
        )}
      </div>
    </TimelineContainerStyles>
  );
};

const Index = () => (
  <ThemeContext.Consumer>
    {(theme) => <Schedule theme={theme} />}
  </ThemeContext.Consumer>
);

export default Index;
