import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import ThemeContext from '../../context/ThemeContext';

import PreviewCompatibleImage from '../../components/PreviewCompatibleImage';
import Shopbar from '../../components/Shopbar';
import ProductForm from '../../components/ProductForm';
import NotFoundPage from '../../pages/404';

const ProductDetails = styled.div(
  (props) => `
  padding: 0 ${props.theme.size.base * 4}px ${props.theme.size.base * 12}px;
  .product-info {
    padding: ${props.theme.size.base * 2}px 0;
    .title {
      font-size: ${props.theme.size.fontSize[8]};
      line-height: ${props.theme.size.lineHeight[8]};
      margin-bottom: ${props.theme.size.base * 4}px;
      a {
        color: ${
          props.theme.dark
            ? props.theme.color.foreground[60]
            : props.theme.color.foreground[40]
        };
        text-decoration: none;
        transition: color 450ms ease;
        &:hover {
          color: ${
            props.theme.dark
              ? props.theme.color.primary[60]
              : props.theme.color.primary[40]
          };
        }
      }
    }
  }
`
);

const ProductPage = ({ location, data }) => {
  const product = data.shopifyProduct;
  const breakpoints = useBreakpoint();
  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <div className='body'>
          {process.env.NODE_ENV === 'development' ? (
            <section className='typesetter'>
              <div className='content'>
                <Shopbar location={location} productTitle={product.title} />
                <ProductDetails breakpoints={breakpoints} theme={theme}>
                  <div>
                    <div>
                      {product.images.map((image) => (
                        <PreviewCompatibleImage
                          key={image.id}
                          imageInfo={{
                            image: image.localFile,
                            alt: product.title,
                          }}
                        />
                      ))}
                    </div>
                    <div className='product-info'>
                      <div className='title'>{product.title}</div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: product.descriptionHtml,
                        }}
                      />
                      <ProductForm product={product} />
                    </div>
                  </div>
                </ProductDetails>
              </div>
            </section>
          ) : (
            <NotFoundPage />
          )}
        </div>
      )}
    </ThemeContext.Consumer>
  );
};

export const query = graphql`
  query ($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            gatsbyImageData(width: 910, quality: 60, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;

export default ProductPage;
