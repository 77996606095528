import React from 'react';
import PropTypes from 'prop-types';

class ImageKuaaLogo extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='100%'
        viewBox='0 0 1920 1080'
        style={{ enableBackground: 'new 0 0 1920 1080' }}
        xmlSpace='preserve'
      >
        <path
          style={{
            transition: 'fill 1200ms ease',
            fill: this.props.colorPrimary,
          }}
          d='M158.7,741.3c0,3,0,4.6,0,6.2c0,83,0,166,0,249c0,2.7,0.1,5.3-0.1,8c-0.3,4.4-2.4,7.2-7.2,7.2c-31,0-62,0-93-0.1
   c-5.4,0-7.4-2.3-7.4-7.7c0-22.7,0-45.3,0-68c0-171.7,0-343.3,0-515c0-2,0-4,0-6c0.2-5,2-6.9,7.1-6.9c10.7-0.1,21.3,0,32,0
   c19.2,0,38.3,0,57.5,0c9.6,0,11.1,1.5,11.1,11.3c0,27,0,54,0,81c0,50.7,0,101.3,0,152c0,1.6,0,3.2,0,6.4c1.9-2.1,2.9-3,3.7-4.1
   c28.9-38.4,57.9-76.9,86.8-115.3c30.4-40.4,60.9-80.8,91.3-121.3c5.2-6.9,11.8-10.1,20.5-10.1c36.7,0.2,73.3,0.1,110,0.1
   c1.9,0,3.8,0.7,5.7,1.1c-0.8,1.9-1.2,4.1-2.4,5.6c-33.3,42.9-66.7,85.6-100,128.4c-39.7,51-79.3,102-119,152.9
   c-1.8,2.3-3,4.1-0.5,7.2c43.1,54.8,86.1,109.8,129.1,164.7c35.9,45.9,71.9,91.7,107.7,137.6c1,1.3,1.4,3.1,2.1,4.7
   c-1.7,0.4-3.4,1.3-5,1.3c-37.5,0.1-75,0-112.5,0c-7.8,0-14.2-3.3-18.9-9.4c-27.7-36.4-55.4-72.9-83-109.4
   c-28.5-37.5-57-75.1-85.5-112.6c-8.6-11.4-17.3-22.8-25.9-34.2C161.8,744.7,160.7,743.6,158.7,741.3z M915.9,417c0-7.5-1.5-9-9.1-9
   c-28.5,0-57,0-85.5,0c-13,0-13.1,0.1-13.1,13.2c0,116.7,0,233.3,0,350c0,32.9-7.8,63.7-25.4,91.6c-10.6,16.7-24.5,30-43.3,36.9
   c-36.7,13.7-76,2-99.4-29.4c-20.5-27.5-30.6-58.9-30.7-92.8c-0.5-119.8-0.2-239.6-0.2-359.5c0-8.6-1.4-10-9.8-10
   c-28.8,0-57.7,0-86.5,0c-9.5,0-10.7,1.2-10.7,10.6c0,120.2-0.1,240.3,0,360.5c0,27.7,3,55.2,10.1,82c8.9,33.8,23.5,65,47.6,90.6
   c49.3,52.3,110.2,74.3,181.2,63c64.7-10.3,113.7-44.9,145-103.1c21.1-39.2,29.1-81.7,29.5-125.7c0.6-58.2,0.2-116.3,0.2-174.5
   C915.9,546.6,915.9,481.8,915.9,417z M1308,1011.7c16.8,0,33.7,0,50.5,0c6,0,6.5-0.6,4.2-6.3c-5.6-14.3-11.2-28.5-16.8-42.7
   c-23-58.5-46-117-69-175.5c-28.7-73.1-57.4-146.2-86.1-219.3c-20.6-52.3-41.1-104.7-61.7-157c-2.6-6.6-6.3-9.9-11.8-9.8
   c-6.2,0.1-9.4,4-11.5,9.4c-7.5,19.2-15.1,38.4-22.6,57.6c-28.5,72.8-56.9,145.6-85.4,218.4c-23.9,61-47.8,122.1-71.7,183.1
   c-17.6,44.8-35.1,89.5-52.7,134.3c-3,7.6-2.7,8,5.5,8c32.8,0,65.7,0,98.5,0c10.1,0,13.7-2.3,17-11.9c12.1-34.7,24.3-69.4,36.2-104.2
   c1.5-4.4,3.5-5.7,8.1-5.7c53,0.2,106,0.2,159,0c5,0,6.8,1.8,8.3,6.2c11.9,34.9,24.1,69.8,36.2,104.7c2.9,8.4,6.8,10.9,15.7,10.9
   C1274.6,1011.7,1291.3,1011.7,1308,1011.7C1308,1011.7,1308,1011.7,1308,1011.7z M1117.4,645.1c0.4,0,0.9-0.1,1.3-0.1
   c17.9,51.6,35.9,103.3,54,155.5c-36.4,0-72.2,0-108.8,0C1081.9,748.4,1099.6,696.8,1117.4,645.1z M1812.3,1011.7c15.7,0,31.3,0,47,0
   c10.4,0,10.2,0.1,6.3-9.8c-13.6-34.3-27.1-68.7-40.6-103.1c-18.4-46.9-36.9-93.8-55.3-140.7c-15-38.1-30-76.2-45-114.3
   c-14.4-36.7-28.8-73.4-43.2-110.1c-11.9-30.2-23.7-60.4-35.6-90.6c-4.6-11.6-8.9-23.3-13.9-34.7c-3.7-8.5-13.6-10-19-3.3
   c-1.6,2-2.7,4.5-3.7,7c-22.1,56.5-44.2,113.1-66.4,169.7c-26.1,66.8-52.4,133.5-78.5,200.3c-29.1,74.2-58.1,148.4-87.1,222.6
   c-2.6,6.6-2.3,7.1,4.8,7.1c32.5,0,65,0,97.5,0c13.2,0,15.6-1.8,19.9-14.2c11.8-34.1,23.8-68.2,35.5-102.3c1.4-3.9,3.3-5.2,7.5-5.2
   c53,0.2,106,0.2,159,0c5.1,0,7.1,1.5,8.7,6.2c11.8,34.8,23.9,69.5,36,104.3c2.9,8.4,7.2,11.3,16,11.3
   C1779,1011.8,1795.6,1011.8,1812.3,1011.7C1812.3,1011.7,1812.3,1011.7,1812.3,1011.7z M1568.1,800.6c18-52.3,35.8-104,53.6-155.8
   c0.4,0,0.7,0,1.1-0.1c17.9,51.7,35.9,103.3,54.1,155.8C1640.4,800.6,1604.7,800.6,1568.1,800.6z'
        />
        <path
          style={{
            transition: 'fill 1200ms ease',
            fill: this.props.colorAccent,
          }}
          d='M1142.9,120.3c0,18,0,36,0,54c0,8.2,3.9,11,11.8,8.4c4.9-1.6,9.7-3.4,14.7-4.9c7.2-2.2,11.7,1.8,10.3,9.1
   c-6,29.2-12,58.3-18.1,87.4c-4.4,21.3-8.9,42.7-13.4,64c-1.1,5.4-3.6,7.4-8,6.9c-4.3-0.5-6.3-3-6.1-8.7c0.9-32.3,2-64.6,2.9-96.9
   c0.3-8.9-3.3-11.5-11.8-8.6c-7.3,2.4-14.3,5.4-21.7,7.5c-2.9,0.8-7.2,1-9.1-0.6c-1.9-1.6-2.7-6.1-2.1-8.9
   c5.7-27.3,11.8-54.6,17.7-81.9c6-27.6,11.9-55.3,18.1-82.8c0.6-2.8,2.2-6.3,4.5-7.7c4.9-2.9,10.2,1,10.2,7.3
   c0.1,11.2,0.1,22.3,0.1,33.5C1142.9,105,1142.9,112.7,1142.9,120.3z M1197.5,367.9c2.4,4.2,6.8,4,11.1-0.6
   c20.6-21.5,41.3-42.9,61.9-64.4c9.5-9.8,19-19.6,28.3-29.6c4.7-5,3.1-9.7-3.4-11.4c-4-1-8.1-2-12.1-3c-7.5-1.9-9-5.3-4.9-11.9
   c16.4-26.1,32.7-52.1,49.2-78.2c2.2-3.4,3.5-6.9-0.2-9.7c-3.5-2.8-6.8-1.3-9.7,1.7c-7.8,8.2-15.8,16.2-23.7,24.3
   c-25.6,26.1-51.4,52.2-76.8,78.5c-1.8,1.8-3.4,5.6-2.7,7.7c0.7,2.1,4.2,3.7,6.8,4.6c4.9,1.5,10,2.4,15.1,3.4
   c11,2.2,13.2,5.4,6.1,15.7c-14.5,20.9-28.6,42.2-42.7,63.3c-1.4,2-2.4,4.3-3.4,6C1196.9,366,1197,367,1197.5,367.9z M928.6,128.1
   c4.1,11.6,8.4,23.1,12.5,34.7c12.2,33.8,24.4,67.5,36.7,101.3c2.5,6.9,7.6,7.7,12.1,2c4.4-5.5,8.6-11,13-16.4
   c3.8-4.6,8.1-4.3,11.2,0.8c3.4,5.5,6.7,11.1,10,16.7c10.9,18.5,21.7,37,32.6,55.5c2.8,4.8,8,5.8,10.4,1.8c1.2-2,1.1-5.5,0.3-7.9
   c-8.2-22.5-16.7-44.9-25-67.3c-6.8-18.2-13.6-36.4-20.4-54.7c-2.3-6.3-7.4-7.4-11.7-2.5c-2.6,3-5.1,6.1-7.7,9.2
   c-5.6,6.8-9,6.6-13.6-0.6c-0.8-1.3-1.7-2.5-2.5-3.8c-15.6-24.8-31.2-49.6-46.9-74.3c-1.2-1.9-3.3-3.3-4.3-4.2
   C929.1,118.2,926.6,122.6,928.6,128.1z M999,358.1c-14.8-11.4-29.6-22.6-44.4-33.9c-12-9.2-24-18.4-36.1-27.6
   c-5.3-4-8.6-2.7-9.7,3.8c-0.6,3.4-0.9,6.9-1.5,10.4c-0.9,4.5-3.3,5.8-7.6,4.1c-1.5-0.6-3-1.4-4.5-2.2c-20.7-9.9-41.4-19.8-62.1-29.8
   c-2.9-1.4-5.8-2.5-7.9,0.9c-2.1,3.3,0,5.6,2.6,7.6c7.6,5.9,15.2,11.9,22.8,17.8c22,17.2,44,34.4,66.2,51.5c1.7,1.3,4.9,2.4,6.6,1.7
   c1.7-0.8,2.8-3.8,3.2-6c0.8-4.6,1-9.3,1.5-13.9c0.7-6.5,3.4-8.1,9.4-5c9.3,4.8,18.6,9.7,27.9,14.5c9.7,5.1,19.5,10.1,29.3,15.1
   c4.2,2.1,8.1,0.3,8.3-4.7C1002,361.3,1000.7,359.4,999,358.1z M1402.3,342.3c-10,2.4-20.1,4.8-30.1,7.2
   c-26.2,6.3-52.4,12.5-78.6,18.8c-7,1.7-7.9,5.2-2.9,10.4c3.1,3.2,6.4,6.4,9.5,9.6c5.5,5.6,4.9,8-2.3,11.2
   c-19.3,8.5-38.7,17.1-58,25.6c-2.9,1.3-4.8,3.2-3.7,6.6c1.1,3.4,3.6,3.6,6.9,2.8c18.7-4.8,37.4-9.4,56.1-14
   c14.5-3.6,29.1-7.1,43.6-10.8c6-1.5,7-5.1,2.9-9.7c-2.1-2.3-4.4-4.6-6.5-6.9c-3.9-4.1-3.5-7.1,1.7-9.7c7.6-3.8,15.3-7.4,22.9-11.1
   c14.5-7,29-14.1,43.6-21c2.8-1.3,4.5-3.3,3.3-6.2c-0.7-1.6-2.8-2.6-3.7-3.4C1404.6,341.9,1403.5,342.1,1402.3,342.3z'
        />
      </svg>
    );
  }
}

export default ImageKuaaLogo;

ImageKuaaLogo.propTypes = {
  colorPrimary: PropTypes.string,
  colorAccent: PropTypes.string,
};

ImageKuaaLogo.defaultProps = {
  colorPrimary: `#ff0000`,
  colorAccent: `#ff0000`,
};
