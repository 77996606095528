import * as React from "react";

const SvgInstagram = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    {...props}
  >
    <rect
      x={95}
      y={95}
      width={322}
      height={322}
      rx={68.31}
      fill="none"
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={30}
    />
    <rect
      x={176.878}
      y={176.878}
      width={158.244}
      height={158.244}
      rx={63.887}
      fill="none"
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={30}
    />
  </svg>
);

export default SvgInstagram;
