import * as React from "react";

const SvgHeartSolid = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="m256 421.3-6.7-5.6C105.7 298.9 66.7 257.7 66.7 190.9c0-55.7 44.5-100.2 100.2-100.2 45.6 0 71.3 25.6 89.1 45.6 17.8-20 43.4-45.6 89.1-45.6 55.7 0 100.2 44.5 100.2 100.2 0 66.8-39 108-182.6 224.9l-6.7 5.5z"
    />
  </svg>
);

export default SvgHeartSolid;
