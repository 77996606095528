import React, { useState, useContext, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import Button from '../Button';
import StoreContext from '../../context/StoreContext';
import ButtonOutline from '../ButtonOutline';
import JSONPretty from 'react-json-pretty';

const ButtonSet = styled.div`
  margin-bottom: 24px;
  Button {
    margin-right: 12px;
  }
`;

const ProductForm = ({ product }) => {
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRange: { minVariantPrice },
  } = product;
  const [variant, setVariant] = useState({ ...initialVariant });
  const [quantity, setQuantity] = useState(1);
  const {
    addVariantToCart,
    store: { client, adding },
  } = useContext(StoreContext);

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant;
  const [available, setAvailable] = useState(productVariant.availableForSale);

  const checkAvailability = useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        // this checks the currently selected variant for availability
        const result = fetchedProduct.variants.filter(
          (variant) => variant.id === productVariant.shopifyId
        );
        if (result.length > 0) {
          setAvailable(result[0].available);
        }
      });
    },
    [client.product, productVariant.shopifyId, variants]
  );

  useEffect(() => {
    checkAvailability(product.shopifyId);
  }, [productVariant, checkAvailability, product.shopifyId]);

  const handleQuantityChange = ({ target }) => {
    setQuantity(target.value);
  };

  const handleOptionChange = (optionIndex, { target }) => {
    const { value } = target;
    const currentOptions = [...variant.selectedOptions];

    currentOptions[optionIndex] = {
      ...currentOptions[optionIndex],
      value,
    };

    const selectedVariant = find(variants, ({ selectedOptions }) =>
      isEqual(currentOptions, selectedOptions)
    );

    setVariant({ ...selectedVariant });
  };
  const handleOptionChange2 = (optionIndex, value) => {
    const currentOptions = [...variant.selectedOptions];

    currentOptions[optionIndex] = {
      ...currentOptions[optionIndex],
      value,
    };

    const selectedVariant = find(variants, ({ selectedOptions }) =>
      isEqual(currentOptions, selectedOptions)
    );

    setVariant({ ...selectedVariant });
  };

  const handleAddToCart = () => {
    addVariantToCart(productVariant.shopifyId, quantity);
  };

  /* 
  Using this in conjunction with a select input for variants 
  can cause a bug where the buy button is disabled, this 
  happens when only one variant is available and it's not the
  first one in the dropdown list. I didn't feel like putting 
  in time to fix this since its an edge case and most people
  wouldn't want to use dropdown styled selector anyways - 
  at least if the have a sense for good design lol.
  */
  const checkDisabled = (name, value) => {
    const match = find(variants, {
      selectedOptions: [
        {
          name: name,
          value: value,
        },
      ],
    });
    if (match === undefined) return true;
    if (match.availableForSale === true) return false;
    return true;
  };

  const checkActive = (name, value) => {
    // find name and value in productVariant.selectedOptions
    const match = find(variant.selectedOptions, {
      name: name,
      value: value,
    });
    if (match === undefined) return false;
    return true;
  };

  const price = Intl.NumberFormat(undefined, {
    currency: minVariantPrice.currencyCode,
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(variant.price);

  return (
    <div className='body'>
      <section className='typesetter'>
        <div className='content'>
          <h3>{price}</h3>
          {options.map(({ id, name, values }, index) => (
            <div key={id} className='field'>
              <label className='label' htmlFor={name}>
                {name}{' '}
              </label>
              <ButtonSet className='control'>
                {values.map((value) => (
                  <ButtonOutline
                    size={-1}
                    shape={4}
                    key={`${name}-${value}`}
                    disabled={checkDisabled(name, value)}
                    onClick={() => handleOptionChange2(index, value)}
                    active={checkActive(name, value)}
                  >
                    {value}
                  </ButtonOutline>
                ))}
              </ButtonSet>
            </div>
          ))}
          <div className='field'>
            <label className='label' htmlFor='quantity'>
              Quantity{' '}
            </label>
            <div className='control'>
              <input
                className='input'
                type='number'
                id='quantity'
                name='quantity'
                min='1'
                step='1'
                onChange={handleQuantityChange}
                value={quantity}
              />
            </div>
          </div>
          <div>
            <Button
              type='submit'
              disabled={!available || adding}
              onClick={handleAddToCart}
              shape={4}
            >
              Add to Cart
            </Button>
          </div>
          {!available && <p>This Product is out of Stock!</p>}
        </div>
      </section>
    </div>
  );
};

ProductForm.propTypes = {
  product: PropTypes.shape({
    descriptionHtml: PropTypes.string,
    handle: PropTypes.string,
    id: PropTypes.string,
    shopifyId: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        originalSrc: PropTypes.string,
      })
    ),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    productType: PropTypes.string,
    title: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        availableForSale: PropTypes.bool,
        id: PropTypes.string,
        price: PropTypes.string,
        title: PropTypes.string,
        shopifyId: PropTypes.string,
        selectedOptions: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      })
    ),
  }),
  addVariantToCart: PropTypes.func,
};

export default ProductForm;
