import React from 'react';
import styled from '@emotion/styled';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import ThemeContext from '../context/ThemeContext';

import Shopbar from '../components/Shopbar';
import RouterTracker from '../components/RouterTracker';
import ProductGrid from '../components/ProductGrid';
import ProductGridLinks from '../components/ProductGrid/links';

const ShopStyles = styled.div(
  (props) => `
  padding: ${props.theme.size.base * 13}px 
    ${props.theme.size.base * 4}px 
    ${props.theme.size.base * 4}px;
  ${
    (props.breakpoints.lg || props.breakpoints.xl) &&
    `
   padding: ${props.theme.size.base * 8}px;
  `
  };
  
  .body {
    position: relative;
    z-index: 500;
    max-width: ${props.theme.size.base * 128}px;
    margin: 0 auto;
  }
`
);

const ShopPage = ({ location }) => {
  const breakpoints = useBreakpoint();
  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <ShopStyles theme={theme} breakpoints={breakpoints}>
          <div className='body'>
            <section className='typesetter'>
              {process.env.NODE_ENV === 'developments' ? (
                <div className='content'>
                  <RouterTracker />
                  <Shopbar location={location} />
                  <ProductGrid />
                </div>
              ) : (
                <div className='content section'>
                  <RouterTracker />
                  <h1 className='title'>Shop</h1>
                  <ProductGridLinks />
                </div>
              )}
            </section>
          </div>
        </ShopStyles>
      )}
    </ThemeContext.Consumer>
  );
};

export default ShopPage;
