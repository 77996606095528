import * as React from "react";

const SvgPlay = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      d="M439.4 227.9 188.635 83.127a32.443 32.443 0 0 0-48.664 28.1v289.55a32.443 32.443 0 0 0 48.664 28.1L439.4 284.1a32.443 32.443 0 0 0 0-56.2Z"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={30}
    />
  </svg>
);

export default SvgPlay;
