import * as React from "react";

const SvgPause = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={30}
      d="M353.074 82v348M159.926 82v348"
    />
  </svg>
);

export default SvgPause;
