import * as React from "react";

const SvgCaretRight = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth={30}
      d="m184 419 130.5-130.5c17.9-17.9 17.9-47 0-65L184 93"
    />
  </svg>
);

export default SvgCaretRight;
