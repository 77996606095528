import * as React from "react";

const SvgList = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    {...props}
  >
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={30}
    >
      <path d="M166 166h260M166 346h260M166 256h260M86 166h0M86 346h0M86 256h0" />
    </g>
  </svg>
);

export default SvgList;
