import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import recordTextureImage from '../../img/record-texture.svg';

const TrackBackgroundStyles = styled.div(
  (props) => `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    left: 50%;
    height: calc(100vh - 420px);
    min-height: 420px;
    transform: translate(-50%, 0);
    filter: blur(0.5rem);
    opacity: 0.4;
  }
`
);

const TrackBackground = (props) => {
  const breakpoints = useBreakpoint();
  return (
    <TrackBackgroundStyles
      theme={props.theme}
      breakpoints={breakpoints}
      background={recordTextureImage}
    >
      <div className={props.playing ? 'playing container' : 'container'}>
        {props.image ? (
          <img
            src={props.image.replace(/170x170bb\.jpg/, '512x512bb.jpg')}
            alt={props.title}
          />
        ) : (
          <img width='170' src={props.theme.themeImage} alt={props.title} />
        )}
      </div>
    </TrackBackgroundStyles>
  );
};

TrackBackground.propTypes = {
  theme: PropTypes.object,
  image: PropTypes.string,
  title: PropTypes.string,
  playing: PropTypes.bool,
};

export default TrackBackground;
