import * as React from "react";

const SvgArrowRight = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    {...props}
  >
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={30}
    >
      <path d="M281.907 256H80M335.667 365.061 423 277.728a30.728 30.728 0 0 0 0-43.456l-87.333-87.333c-19.358-19.358-52.456-5.648-52.456 21.728v174.666c0 27.376 33.098 41.086 52.456 21.728Z" />
    </g>
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={30}
    >
      <path d="M281.907 256H80M335.667 365.061 423 277.728a30.728 30.728 0 0 0 0-43.456l-87.333-87.333c-19.358-19.358-52.456-5.648-52.456 21.728v174.666c0 27.376 33.098 41.086 52.456 21.728Z" />
    </g>
  </svg>
);

export default SvgArrowRight;
