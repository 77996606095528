import * as React from "react";

const SvgAdd = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={30}
      className="add_svg__st0"
      d="M255.9 56.6v398.8M56.5 256h398.8"
    />
  </svg>
);

export default SvgAdd;
