import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { formatRelative, lightFormat } from 'date-fns';
import Moment from 'react-moment';

import Badge from '../../components/Badge';
import Header from '../../components/Header';

import ThemeContext from '../../context/ThemeContext';
import RouterTracker from '../../components/RouterTracker';
import ButtonOutline from '../../components/ButtonOutline';

import { PlaylistTable } from '../../components/PlaylistTable';
import { navigate } from 'gatsby-link';

import PlayerContext from '../../context/PlayerContext';
import SvgPlay from '../../icons/Play';
import SvgAdd from '../../icons/Add';
import SvgClose from '../../icons/Close';
import SvgPause from '../../icons/Pause';

var JSONPrettyMon = require('react-json-pretty/dist/monikai');

const ShowStyles = styled.div(
  (props) => `
  max-width: ${props.theme.size.base * 128}px;
  margin: 0 auto;
  padding: ${props.theme.size.base * 4}px;
  .section-title {
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: ${props.theme.size.base * 3}px;
    line-height: ${props.theme.size.base * 3}px;
    font-weight: 400;
    color: ${props.theme.color.tertiary[60]};
  }
  .body {
    position: relative;
    z-index: 500;
    padding-top: ${props.theme.size.base * 10}px;
  }
  .post-item {
    margin-bottom: ${props.theme.size.base * 8}px;
    ${
      (props.breakpoints.lg || props.breakpoints.xl) &&
      `
      display: flex;
    `
    };
  }
  .post-info {
    flex-grow: 1;
    h1 {
      margin: 0;
      padding: 0;
      color: ${props.theme.color.accent[60]};
    }
    p {
      margin: 0;
      padding: 0;
    }
  }
  .post-description {
    margin-bottom: ${props.theme.size.base * 2}px;
  }
  .post-time {
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-size: ${props.theme.size.fontSize[1]};
    line-height: ${props.theme.size.lineHeight[1]};
    display: flex;
    align-items: center;
    .on-air {
      margin-right: ${props.theme.size.base * 2}px;
    }
  }
  .post-image {
    flex-shrink: 0;
    margin: 0 0 ${props.theme.size.base * 4}px 0;
    width: ${props.theme.size.base * 20}px;
    height: ${props.theme.size.base * 20}px;
    border-radius: ${props.theme.size.base}px;
    overflow: hidden;
    ${
      (props.breakpoints.lg || props.breakpoints.xl) &&
      `
      margin: 0 ${props.theme.size.base * 4}px 0 0;
    `
    };
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    padding: ${props.theme.size.base * 4}px;
  }
  `
);

const ArchiveList = styled.div(
  (props) => `
  width: 100%;
  margin-bottom: ${props.theme.size.base * 4}px;
  .archive-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: ${props.theme.size.base * 2}px;
    padding-bottom: ${props.theme.size.base * 2}px;
    border-bottom: 1px solid ${props.theme.color.secondary[30]};
    &:last-of-type {
      border-bottom: none;
    }
  }
  .archive-info {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .archive-image {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${props.theme.size.base * 0.5}px;
    width: ${props.theme.size.base * 9}px;
    height: ${props.theme.size.base * 9}px;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .archive-time {
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    color: ${props.theme.color.foreground[80]};
    transition: color 450ms ease;
  }
  .archive-title {
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    font-weight: 600;
    color: ${props.theme.color.primary[50]};
    transition: color 450ms ease;
    a:visted,
    a {
      color: ${props.theme.color.primary[50]};
      transition: color 450ms ease;
      &:hover {
        color: ${props.theme.color.primary[65]};
      }
    }
  }
  .archive-djs {
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    color: ${props.theme.color.secondary[50]};
    transition: color 450ms ease;
  }
  .archive-actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${props.theme.size.base * 2}px;
  }

  ${
    (props.breakpoints.md || props.breakpoints.lg || props.breakpoints.xl) &&
    `
    .archive-item {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .archive-actions {
      width: auto;
      margin-left: auto;
      margin-top: 0;
    }
  `
  };
  `
);
const DjList = styled.div(
  (props) => `
  margin-bottom: ${props.theme.size.base * 4}px;
  .dj-item {
    margin-top: ${props.theme.size.base * 2}px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    ${
      (props.breakpoints.lg || props.breakpoints.xl) &&
      `
      margin-right: ${props.theme.size.base * 2}px;
    `
    };
  }
  .dj-social {
    font-size: ${props.theme.size.fontSize[1]};
    line-height: ${props.theme.size.lineHeight[1]};
    margin-bottom: ${props.theme.size.base * 0.5}px;
    a {
      margin-right: ${props.theme.size.base * 2}px;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      color: ${
        props.theme.dark
          ? props.theme.color.tertiary[60]
          : props.theme.color.tertiary[40]
      };
      text-decoration: none;
      transition: color 450ms ease;
      &:hover {
        color: ${
          props.theme.dark
            ? props.theme.color.primary[60]
            : props.theme.color.primary[40]
        };
      }
    }
  }
  .dj-name {
    font-size: ${props.theme.size.fontSize[3]};
    line-height: ${props.theme.size.lineHeight[3]};
    font-weight: 600;
    color: ${props.theme.color.foreground[100]};
    margin-bottom: ${props.theme.size.base * 0.5}px;
    a {
      color: ${props.theme.color.foreground[100]};
      text-decoration: none;
      &:hover {
        color: ${props.theme.color.tertiary[60]};
      }
    }
  }
  .dj-bio {
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    color: ${props.theme.color.foreground[40]};
    margin-bottom: ${props.theme.size.base * 0.5}px;
    font-weight: 200;
    p {
      margin: 0;
      font-size: ${props.theme.size.fontSize[2]};
      line-height: ${props.theme.size.lineHeight[2]};
    }
  }
  .dj-image {
    flex-shrink: 0;
    margin: 0 ${props.theme.size.base * 2}px 0 0;
    width: ${props.theme.size.base * 6}px;
    height: ${props.theme.size.base * 6}px;
    border-radius: ${props.theme.size.base * 4}px;
    overflow: hidden;
    ${
      (props.breakpoints.lg || props.breakpoints.xl) &&
      `
      margin: 0 ${props.theme.size.base * 2}px;
      width: ${props.theme.size.base * 8}px;
      height: ${props.theme.size.base * 8}px;
    `
    };
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  `
);

const Show = (props) => {
  const breakpoints = useBreakpoint();
  const [post, setPost] = useState([]);
  const [archives, setArchives] = useState([]);

  const getArchives = async () => {
    try {
      const _archives = await axios.get(
        `https://kuaa-chat-api-2nftz.ondigitalocean.app/archives`
      );
      setArchives(_archives.data); // set State
    } catch (err) {
      console.error(err.message);
    }
  };

  const getShow = async () => {
    try {
      const showID = new URLSearchParams(window.location.search);
      const userPosts = await axios.get(
        `https://kuaa-api-7xgcl.ondigitalocean.app/show/${showID.get('id')}/55`
      );
      setPost(userPosts.data); // set State
    } catch (err) {
      console.error(err.message);
    }
  };

  const getId = (str) => {
    return str.substring(str.lastIndexOf('/') + 1, str.length);
  };

  // Set update loop
  useEffect(() => {
    getShow();
    getArchives();
  }, []);

  function isLive(start, end) {
    var dateNow = new Date();
    var dateStart = new Date(start);
    var dateEnd = new Date(end);
    return dateNow > dateStart && dateEnd > dateNow;
  }

  function PlaylistNumber(value) {
    const padding = 3;
    var zeroes = new Array(padding + 1).join('0');
    return (zeroes + value).slice(-padding);
  }
  function createMarkup(html) {
    return { __html: html };
  }

  const compareDatetimes = (start, duration) => {
    if (!start || !duration) {
      return;
    }
    const todayDate = new Date();
    const startDate = new Date(start);
    const timeAgo = todayDate.getTime() - startDate.getTime();

    return timeAgo / 1000;
  };

  const isInTracklist = (track) => {
    // check if track is in props.context.tracklistq
    return props.context.tracklist.some(
      (tracklist) => tracklist.id === track.id
    );
  };

  const isActiveTrack = (track) => {
    // check if track is props.context.currentTrack
    return props.context.currentTrack.id === track.id;
  };

  const calendarStrings = {
    lastDay: '[Yesterday], LT',
    sameDay: '[Today], LT',
    nextDay: '[Tomorrow], LT',
    lastWeek: '[last] dddd, LT',
    nextWeek: 'dddd, LT',
    sameElse: 'LL, LT',
  };

  return (
    <ShowStyles theme={props.theme} breakpoints={breakpoints}>
      <RouterTracker />
      {post && (
        <div>
          {post.image && (
            <Header
              opacity={{ dark: 0.3, light: 0.5 }}
              theme={props.theme}
              image={post.image}
              alt={`header for ${post.title}`}
            />
          )}
          <div className='body'>
            <div className='post-item'>
              <div className='post-image'>
                <img src={post.image} alt={post.title} />
              </div>
              <div className='post-info'>
                <div className='post-time'>
                  {isLive(post.start, post.end) && (
                    <Badge className='on-air'>On Air</Badge>
                  )}
                </div>
                <h1>{post.title}</h1>
                {post.description && (
                  <div
                    className='post-description'
                    dangerouslySetInnerHTML={createMarkup(post.description)}
                  ></div>
                )}
              </div>

              <DjList theme={props.theme} breakpoints={breakpoints}>
                {post.djs &&
                  post.djs.map((dj, index) => (
                    <div key={`${dj}-${index}`} className='dj-item'>
                      <div className='dj-image'>
                        <img src={dj.image} alt={dj.name} />
                      </div>
                      <div>
                        <div className='dj-name'>{dj.name}</div>
                        {(dj.website || dj.email) && (
                          <div className='dj-social'>
                            {dj.website && (
                              <a
                                href={dj.website}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                Website
                              </a>
                            )}
                            {dj.email && (
                              <a
                                href={`mailto:${dj.email}`}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                Email
                              </a>
                            )}
                          </div>
                        )}
                        {dj.bio && (
                          <div
                            className='dj-bio'
                            dangerouslySetInnerHTML={createMarkup(dj.bio)}
                          ></div>
                        )}
                      </div>
                    </div>
                  ))}
              </DjList>
            </div>
            {archives &&
              archives.filter((archive) => archive.show.id === post.id).length >
                0 && (
                <div>
                  <h2 className='section-title'>Archives</h2>
                  <div className='scroll-x'>
                    <ArchiveList
                      className='data-table'
                      theme={props.theme}
                      breakpoints={breakpoints}
                    >
                      {
                        // filter archives where id is not equal to current show
                        archives
                          .filter((archive) => archive.show.id === post.id)
                          .map((archive, index) => (
                            <div className='archive-item' key={index}>
                              <div className='archive-info'>
                                <div className='archive-image'>
                                  {isActiveTrack(archive) &&
                                  props.context.playing ? (
                                    <ButtonOutline
                                      active={true}
                                      square={true}
                                      shape={4}
                                      onClick={() =>
                                        props.context.setPlaying(false)
                                      }
                                      label='Pause Audio'
                                    >
                                      <SvgPause />
                                    </ButtonOutline>
                                  ) : (
                                    <ButtonOutline
                                      square={true}
                                      shape={4}
                                      onClick={() =>
                                        props.context.addToTracklist(
                                          {
                                            id: archive.id,
                                            name: archive.show.title,
                                            url: props.context.mediaLink(
                                              archive.file
                                            ),
                                          },
                                          true
                                        )
                                      }
                                      label={`Play ${archive.show.title} Episode`}
                                    >
                                      <SvgPlay />
                                    </ButtonOutline>
                                  )}
                                </div>
                                <div>
                                  <div className='archive-title'>
                                    {archive.show.title}
                                  </div>
                                  <div class='archive-djs'>
                                    {archive.djs.map((dj, index) => (
                                      <span key={index}>
                                        {dj.name}
                                        {index < archive.djs.length - 1 && (
                                          <span>&nbsp;&nbsp;</span>
                                        )}
                                      </span>
                                    ))}
                                  </div>
                                  <Moment calendar={calendarStrings}>
                                    {archive.start}
                                  </Moment>
                                </div>
                              </div>

                              <div class='archive-actions'>
                                {isInTracklist(archive) ? (
                                  <ButtonOutline
                                    active={true}
                                    shape={4}
                                    color='secondary'
                                    size={-2}
                                    onClick={() =>
                                      props.context.removeFromTracklist(archive)
                                    }
                                    label={`Remove ${archive.show.title} From Queue`}
                                  >
                                    <SvgClose
                                      style={{
                                        marginRight: props.theme.size.base * 1,
                                      }}
                                    />
                                    In Queue
                                  </ButtonOutline>
                                ) : (
                                  <ButtonOutline
                                    shape={4}
                                    color='secondary'
                                    size={-2}
                                    onClick={() =>
                                      props.context.addToTracklist(
                                        {
                                          id: archive.id,
                                          name: archive.show.title,
                                          url: props.context.mediaLink(
                                            archive.file
                                          ),
                                        },
                                        false
                                      )
                                    }
                                    label={`Add ${archive.show.title} to Queue`}
                                  >
                                    <SvgAdd
                                      style={{
                                        marginRight: props.theme.size.base * 1,
                                      }}
                                    />
                                    Add to Queue
                                  </ButtonOutline>
                                )}
                              </div>
                            </div>
                          ))
                      }
                    </ArchiveList>
                  </div>
                </div>
              )}
            <h2 className='section-title'>Playlist</h2>
            {post.playlist && (
              <div className='scroll-x'>
                <PlaylistTable
                  width='100%'
                  className='data-table'
                  theme={props.theme}
                  breakpoints={breakpoints}
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>Song</th>
                      <th>Artist</th>
                      <th>Release</th>
                      <th>Play Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {post.playlist.map((play, index) => (
                      <tr key={`${play.song}-${index}`}>
                        <td className='shrink'>
                          {play.image ? (
                            <div className='play-image'>
                              <img
                                width='90'
                                src={play.image}
                                alt={`${play.song} - ${play.artist} - ${play.release}`}
                              />
                            </div>
                          ) : (
                            <div className='play-image-placeholder'>
                              {play.release ? play.release : play.artist}
                            </div>
                          )}
                        </td>
                        <td className='play-song'>{play.song}</td>
                        <td className='play-artist'>{play.artist}</td>
                        <td className='play-release'>{play.release}</td>
                        {/* <td>{play.released}</td>
                          <td>{play.label}</td>
                          <td>{play.isrc}</td> */}
                        <td className='play-time nowrap shrink'>
                          {play.start && play.duration ? (
                            <>
                              {compareDatetimes(play.start, play.duration) >
                              play.duration ? (
                                formatRelative(new Date(play.start), new Date())
                              ) : (
                                <Badge>Playing Now</Badge>
                              )}
                            </>
                          ) : (
                            <>
                              {formatRelative(new Date(play.start), new Date())}
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </PlaylistTable>
              </div>
            )}
          </div>
        </div>
      )}
      <div className='footer'>
        <ButtonOutline
          onClick={() => {
            navigate(`/playlist`);
          }}
          size={-1}
          shape={4}
          color='tertiary'
        >
          View Full Playlist
        </ButtonOutline>
      </div>
    </ShowStyles>
  );
};

const Index = () => (
  <PlayerContext.Consumer>
    {(context) => (
      <ThemeContext.Consumer>
        {(theme) => <Show theme={theme} context={context} />}
      </ThemeContext.Consumer>
    )}
  </PlayerContext.Consumer>
);

export default Index;
