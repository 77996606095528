import React from 'react';
import PropTypes from 'prop-types';

class ImageUaaLogo extends React.Component {
  render() {
    return (
      <svg
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        viewBox='0 0 900 473'
        width='100%'
        style={{ enableBackground: 'new 0 0 900 473' }}
        xmlSpace='preserve'
      >
        <path
          style={{
            transition: 'fill 450ms ease',
            fill: this.props.colorPrimary,
          }}
          d='M450,472.5c-148.2,0-296.4,0-444.7,0.1c-4.4,0-5.4-1-5.4-5.4C0.1,313.4,0.1,159.5,0,5.6c0-4,0.7-5.2,5-5.2
		c296.6,0.1,593.2,0.1,889.8,0c4.2,0,5.1,1,5.1,5.1c-0.1,153.7-0.1,307.5,0,461.2c0,4.7-0.8,6-5.8,6
		C746.1,472.5,598.1,472.5,450,472.5z M450.1,462.2c144.7,0,289.4,0,434.1,0.1c4.5,0,5.5-1,5.5-5.5c-0.1-146.9-0.1-293.8,0-440.6
		c0-4.2-0.6-5.6-5.3-5.6c-289.4,0.1-578.8,0.1-868.3,0c-4.6,0-5.7,1.1-5.7,5.7c0.1,146.7,0.1,293.4,0,440.1c0,4.3,0.7,5.8,5.6,5.8
		C160.6,462.2,305.4,462.2,450.1,462.2z'
        />
        <path
          style={{
            transition: 'fill 450ms ease',
            fill: this.props.colorPrimary,
          }}
          d='M872.3,228.9c0,70.1,0,140.2,0.1,210.3c0,2.5-0.5,3.8-3.2,3.4c-0.8-0.1-1.7,0-2.5,0
		c-277.7,0-555.4,0-833.1,0.1c-4.6,0-5.4-1.2-5.3-5.5c0.1-67.8,0.1-135.5,0.1-203.3c0-1.7,0.1-3.3,0.2-5c264.2,0,528.4,0,792.6,0
		C838.2,229,855.3,228.9,872.3,228.9z M609.8,353c-2.4-2.1-6.2-4.8-9.4-8.1c-20.8-21.4-41.5-42.9-62.3-64.4c-1-1-1.9-2.8-3.6-2.2
		c-2,0.7-1.5,2.7-1.6,4.3c-0.2,4.5-0.2,9-0.4,13.5c-0.7,24.2,0,48.4-1.3,72.5c-0.5,8.5-0.7,8.8-9,9.7c-1.2,0.1-2.7,0.3-2.7,1.5
		c0.1,1.3,1.6,1.4,2.8,1.4c10.3,0,20.6,0,31,0c1.1,0,2.7,0.1,2.8-1.3c0.1-1.5-1.5-1.4-2.6-1.5c-8.8-0.9-10.1-2.2-10.5-11
		c-0.5-11.5-0.8-23-1.1-34.5c-0.2-8.2,0-16.4,0-24.6c0.2,0,0.6,0,0.8,0.2c21.8,24.9,45.9,47.5,69.2,70.9c1.5,1.5,3.1,3.9,5.2,3.2
		c2.7-0.9,1.8-4,1.8-6.2c0.5-25.7,0.9-51.4,1.3-77c0.1-3-0.1-6,0.3-9c0.5-4.5,2.6-7.5,7.7-7.3c1.2,0.1,3.2,0.4,3.1-1.4
		c-0.1-1.7-2-1.4-3.3-1.3c-9.5,0.7-19,0.1-28.5,0c-1.4,0-3.6-0.5-3.5,1.5c0.1,2,2.4,1.1,3.8,1.2c7,0.3,9.1,2.3,9.4,9.4
		c0.3,6,0.5,12,0.5,18C609.8,325.2,609.8,339.9,609.8,353z M153.9,379.3c1.2,0.8,1.5,1.1,1.7,1.1c10.8,1.3,21.6,0.5,32.5,0.7
		c1,0,1.9-0.8,1.9-1.3c-0.2-1.4-1.5-1.5-2.7-1.4c-5.2,0.4-9-1.8-11.7-6.2c-2-3.3-3.7-6.7-5.1-10.2c-10.4-25.9-20.8-51.8-31.3-77.6
		c-1-2.5-1.5-6.6-4.4-6.7c-3.1-0.1-3.4,4-4.4,6.5c-11.2,27.9-22.4,55.8-33.6,83.7c-2.4,6.1-5.8,10.9-13.3,10.6
		c-1.1-0.1-2.7-0.3-2.8,1.3c0,2,1.8,1.5,3,1.5c9.5,0,19,0,28.5,0c1.2,0,2.8,0.1,2.9-1.3c0.1-1.9-1.7-1.5-3-1.6
		c-6-0.5-7.2-1.7-5.5-7.4c2.3-7.6,5.4-15,8.1-22.5c0.7-2,2.2-2.1,4-2.1c8.2,0.1,16.4,0.2,24.5-0.1c3-0.1,4,1.1,4.9,3.6
		c2.8,7.8,5.7,15.6,8.8,23.3C158,376.1,158.4,378.3,153.9,379.3z M478.6,379.3c0.3,0.6,0.5,1.2,0.8,1.8c11.1,0,22.2,0,33.3,0
		c0.9,0,1.8-0.6,1.7-1.4c-0.1-1.2-1.2-1.4-2.3-1.3c-7.5,0.7-11.2-3.9-14.1-9.8c-1.4-2.8-2.7-5.7-3.9-8.6
		c-10.4-25.9-20.8-51.8-31.3-77.6c-0.8-1.9-1.3-4.8-3.7-4.8c-2.5,0-2.9,3-3.6,4.9c-4.6,11.2-9,22.5-13.5,33.8
		c-7,17.4-13.9,34.8-21,52.2c-2.2,5.5-5.4,10-12.2,10c-1.4,0-3.6-0.6-3.6,1.4c0,2,2.3,1.4,3.7,1.4c9,0,18,0,26.9,0
		c1.5,0,3.7,0.5,3.8-1.3c0.1-1.9-2.2-1.4-3.6-1.5c-5.1-0.3-6.2-1.6-5-6.5c1.9-8.1,5.7-15.6,8.3-23.5c0.7-2.1,2.2-2.1,4-2
		c8.2,0.1,16.4,0.2,24.5-0.1c3-0.1,4,1.2,4.9,3.7c2.8,7.8,5.7,15.6,8.8,23.3C482.5,376.1,482.7,378.3,478.6,379.3z M735.1,294.8
		c-0.1,0-0.1,0-0.2,0c0.2-3.2,0.4-6.3,0.6-9.5c0.1-1.6,0-2.9-2.1-3.3c-16.6-3.1-33.3-5.5-50.1-1.8c-20.1,4.4-33.9,16.5-38.5,36.7
		c-4.6,20-0.8,38.5,15.8,52.1c20.4,16.6,44.1,16.3,68.3,11.2c2.8-0.6,4-2.8,4.5-5.4c0.7-4.4,1.5-8.9,2.1-13.3
		c0.2-1.7,1.7-4.3-0.6-4.9c-2.4-0.6-2,2.4-2.6,3.9c-2.5,6.9-7.1,11.7-14.3,13.3c-16.6,3.8-31.5,0.5-43.4-12.4
		c-13.2-14.3-14.7-31.5-10.6-49.6c3-13.1,10.8-22,24-25.3c8-2,16.2-1.6,24.3,0.3c10.6,2.5,18.5,7.5,19.1,19.6
		c0.1,1.1,0.5,2.2,1.5,2.2c1,0,1.5-1.1,1.5-2.3C734.7,302.5,734.9,298.6,735.1,294.8z M811.2,300.2c2-6.8,1.3-13.4,2.4-19.6
		c0.2-1.3-0.9-1.3-2.1-1c-5.9,1.4-11.9,1.5-17.9,1.4c-13.5-0.2-27-0.4-40.5-0.6c-1.3,0-3.2-0.3-3.4,1.3c-0.2,2,1.8,1.4,3,1.5
		c8.6,0.6,9.9,1.8,9.9,10.7c0.1,21.2,0.1,42.4,0,63.5c0,4.7-0.1,9.3-0.5,14c-0.3,4.5-2.2,7.7-7.5,7.1c-1.2-0.1-3.1-0.4-2.9,1.6
		c0.1,1.4,1.8,1.2,2.9,1.2c6.5-0.1,13-0.3,19.4-0.3c11.8,0.1,23.7,0.4,35.5,0.5c1.9,0,4.3,0.3,5-2.2c1.7-6,2.6-12.1,1.7-18.5
		c-2.4,0.4-2.3,2.1-2.5,3.2c-0.9,6.3-4.8,9.3-10.7,9.9c-5,0.5-10,0.5-15-0.1c-5.3-0.6-8.1-3.4-8.1-8.8c0-10.5,0-21-0.1-31.5
		c0-2.3,1-2.8,3-2.7c3.8,0.2,7.7,0.2,11.5,0.3c10,0.2,10,0.2,12.9,9.6c0.2,0.6,0.2,1.4,1,1.5c1.1,0.2,1.5-0.7,1.6-1.5
		c0.5-6.8,0.9-13.6,1.5-21.9c-4.5,5.2-9.4,4.8-14.2,5c-4.3,0.1-8.7-0.1-13,0.3c-3.3,0.3-4.4-0.6-4.3-4c0.2-9.3,0.3-18.7,0-28
		c-0.1-3.9,1.1-4.8,4.8-4.3c3.6,0.5,7.3,0.2,11,0.2c10.4,0.2,11.1,0.8,13.9,10.8C809.7,299,810.1,299.2,811.2,300.2z M238,381.2
		c0,0.1,0,0.2,0,0.2c3.5,0,7-0.1,10.5,0c13.7,0.7,13.7,0.7,15.5-12.6c0.2-1.5,0.6-3,0.5-4.5c0-1.4,0.9-3.5-0.9-3.9
		c-2.2-0.4-1.7,1.9-2,3.2c-1.9,6.6-4.6,8.9-11.5,9.8c-4.5,0.6-8.9,0.6-13.4,0.1c-10.9-1.3-12-2.3-12.1-13.4
		c-0.1-22.7-0.1-45.4,0-68.1c0-7.4,1.4-8.6,8.7-9.1c1.2-0.1,3.2,0.7,3.3-1.1c0.1-2.3-2.1-1.6-3.4-1.6c-11.5,0.4-23,0.5-34.5,0
		c-0.5,0-1,0-1.5,0c-1.1,0-2.2,0.3-2.2,1.4c0,1.3,1.2,1.2,2.2,1.4c9.5,1.3,9.8,1.6,10,11.2c0.2,17,0.3,34,0.3,51.1
		c0,8.3-0.3,16.7-0.7,25c-0.2,4.1-0.9,8.2-6.6,8.1c-1.4,0-3.8-0.6-3.8,1.3c0,2.3,2.4,1.5,3.9,1.5C212.7,381.2,225.4,381.2,238,381.2
		z M304.9,326.6c0,0-0.1,0-0.1,0c0-11.2-0.1-22.4,0-33.5c0.1-8.2,1.4-9.5,9.4-10c1.3-0.1,3.1,0.2,3-1.5c0-1.2-1.7-1.3-2.9-1.2
		c-12,0.5-24,0.5-36,0c-1.3,0-3-0.3-3,1.5c0,1.5,1.7,1.1,2.8,1.2c7.9,0.7,9.4,2.2,9.4,10.2c0.1,17,0.2,34,0.1,51
		c0,9.2,0.4,18.3-0.8,27.5c-0.6,4.3-2.3,6.8-7,6.6c-1.3-0.1-3.3-0.5-3.2,1.3c0,2.2,2.2,1.5,3.5,1.5c17.5-0.5,35,0.2,52.4,0.4
		c9.8,0.1,9.9,0.1,11.5-9.8c0.4-2.6,0.6-5.3,0.8-7.9c0.1-1.3,0.7-3.1-0.9-3.4c-1.9-0.4-1.7,1.8-1.9,2.9c-1.5,6.6-5.3,9.9-12.1,10.4
		c-4.5,0.3-8.9,0.4-13.4-0.1c-9.8-1.2-11.4-2.4-11.6-12.4C304.7,349.7,304.9,338.1,304.9,326.6z M399.8,379.8
		c-1.3-2.3-3.5-1.3-5.4-1.4c-7-0.4-8.3-1.6-8.7-8.6c-1.4-23.8-0.3-47.6-0.6-71.5c0-2.5,0.3-5,0.3-7.5c0.1-5.4,2.7-8,8.1-7.8
		c1.2,0.1,2.7,0.1,2.7-1.4c0-1.4-1.5-1.5-2.7-1.4c-11.3,0.6-22.6,0.4-33.9,0c-1.1,0-2.7-0.1-2.6,1.5c0.1,1.6,1.7,1.1,2.8,1.2
		c6.8,0.6,8.1,1.7,8.2,8.6c0.2,24,0.1,48,0.1,72c0,2.8-0.1,5.7-0.4,8.5c-0.4,4-2.3,6.5-6.8,6.2c-1.4-0.1-3.7-0.5-3.7,1.4
		c0,1.9,2.3,1.3,3.7,1.4c11.6,0,23.3,0,34.9,0C397.3,381.2,398.9,381.6,399.8,379.8z'
        />
        <path
          style={{
            transition: 'fill 450ms ease',
            fill: this.props.colorPrimary,
          }}
          d='M414.4,79.1c2.2-1,38.6-1.1,41.1-0.1c-0.6,2.1-2.4,1.4-3.7,1.5c-5.1,0.1-7.1,2.8-7.2,7.6
		c-0.7,26.4-1,52.7,0.3,79c0.4,7.3,1.6,8.4,9.1,8.9c1.6,0.1,3.2,0.4,5.3,0.7c-3.1,2-39.1,2.3-42.9,0.8c0.8-1.9,2.5-1.4,3.9-1.4
		c4.6,0.1,6.2-2.6,6.6-6.5c1.4-13.1,0.6-26.3,0.9-39.5c0-2-0.8-2.6-2.6-2.6c-15.9,0-31.7,0-47.6,0c-1.9,0-2.5,0.8-2.5,2.7
		c0,13-0.1,26,0.9,39c0.3,4.3,2.6,6.6,7.1,6.7c2.2,0,4.6-0.2,6.6,1.9c-14.1,0-28.3,0-42.5,0c0.7-1.8,2-1.6,3.1-1.8
		c6.2-0.7,7.3-1.8,7.7-8c1.5-26.2,0.8-52.4,0.5-78.6c-0.1-6.9-2.1-8.5-9.2-9c-1.4-0.1-3.1,0.6-4.4-2.4c14.2,0,27.9,0.7,42-0.3
		c-1.1,3.4-3.1,2.6-4.5,2.7c-4.2,0.2-6.4,2.2-6.7,6.5c-0.8,10.2-0.4,20.3-0.6,30.5c0,2.5,1.1,3.1,3.4,3.1c15.4-0.1,30.7-0.1,46.1,0
		c2.8,0,3.3-1.1,3.3-3.6c-0.1-8.5,0-17-0.2-25.5c-0.2-9.2-1.3-10.2-10.3-10.9C416.1,80.4,414.9,80.6,414.4,79.1z'
        />
        <path
          style={{
            transition: 'fill 450ms ease',
            fill: this.props.colorPrimary,
          }}
          d='M712.5,176.8c-11.1,5-29,1.3-36.7-6.9c-8.2-8.8-14.5-19.1-21.6-28.7c-2.5-3.4-12.7-6-16.2-4.2
		c-1.2,0.6-1.3,1.8-1.3,3c0.2,8.5,0.3,17,0.6,25.5c0.3,8.9,1.2,9.6,10.2,10.5c1.1,0.1,2.4-0.2,3.3,1.9c-13.8,0-27.3,0-40.9,0
		c0.3-1.7,1.4-1.6,2.4-1.7c7-0.8,7.6-1.4,8.2-8.6c1.5-19.3,0.4-38.7,0.7-58.1c0.1-6.7,0.2-13.4-0.2-20c-0.4-7.1-2.1-8.5-9.3-8.9
		c-1.3-0.1-2.7,0.5-3.7-1.1c0.2-1.5,1.3-1.6,2.5-1.5c15.6,1.5,31.3-0.9,47,0.7c21.1,2.2,30.4,21.5,19,39.5
		c-2.8,4.4-6.4,8.1-10.4,11.4c-2.1,1.7-2,2.9-0.4,4.8c8.4,10.2,16.2,20.7,25.2,30.5C696.6,171.3,703.1,176.6,712.5,176.8z
		 M636.7,106.6c0,6,0,12,0,18c0,1.8-0.2,3.8,1.7,4.9c6.9,4.4,20.3,0.8,24.1-6.7c3.5-7,3.9-14.4,2.2-21.9
		c-2.7-12.1-11.6-18-23.9-16.6c-3.4,0.4-4.1,1.9-4.1,4.8C636.7,95,636.6,100.8,636.7,106.6z'
        />
        <path
          style={{
            transition: 'fill 450ms ease',
            fill: this.props.colorPrimary,
          }}
          d='M46.5,79.1c1.3-2,3.3-1.2,5-1.2c10.3,0.4,20.7,0.8,31,0c1.7-0.1,4.7-0.9,4.9,1c0.2,2.5-2.8,1.4-4.4,1.6
		c-3.3,0.5-5.5,2-5.6,5.3c-0.9,22.5-2.5,45,1.2,67.4c2.2,13.2,12.4,20.4,25.9,20.1c13-0.3,21.6-7.8,24.2-20.8
		c1-4.8,1.1-9.6,1.1-14.4c0.1-16,0-32-0.2-48.1c-0.1-7.8-1.3-8.9-9.4-9.5c-1.1-0.1-2.4,0.2-3-1.2c0.4-1.4,1.6-1.4,2.8-1.4
		c9.8,0.4,19.7,0.7,29.5,0c1.1-0.1,2.9-0.4,3,1c0.2,2.1-1.8,1.4-2.9,1.5c-6.3,0.8-7.7,2.1-7.7,8.6c-0.2,15.5-0.1,31-0.2,46.6
		c-0.1,6.7-0.8,13.3-2.9,19.7c-4.4,13.7-16.6,23.4-30.9,24.6c-6,0.5-12.1,0.6-18-0.4c-17.5-3.2-27.7-14.9-29.3-33
		c-1.5-17.3-0.3-34.7-0.8-52c0-0.8,0-1.7,0-2.5c-0.3-10-0.7-10.5-10.7-11.5C47.8,80.4,46.9,80.3,46.5,79.1z'
        />
        <path
          style={{
            transition: 'fill 450ms ease',
            fill: this.props.colorPrimary,
          }}
          d='M532,178.7c-11.4-0.7-22.8-0.3-34.4-0.1c0.5-2.1,1.1-2.5,2.1-2.5c7.9,0.6,11.8-4,14.4-10.6
		c8-20.1,16-40.1,24.1-60.2c3.3-8.3,6.7-16.7,10-25c0.8-2,1.4-5,3.5-5.1c2.5,0,3.1,3.1,3.9,5.2c8.6,21.3,17.1,42.6,25.7,63.8
		c2.8,6.9,5.7,13.9,8.7,20.7c2.9,6.6,7,11.9,15.3,11.1c0.4,0,0.9,0.3,1.4,0.5c0.1,0.1,0.2,0.3,0.7,1.5c-12,0.7-23.5,0.4-35.5-0.7
		c4.2-1.9,3.3-4.5,2.1-7.5c-3-7.4-5.9-14.8-8.5-22.4c-1-2.7-2.1-4-5.3-3.9c-8.2,0.3-16.3,0.2-24.5,0c-2.4,0-3.6,0.8-4.4,3
		c-2.4,6.7-5.1,13.4-7.3,20.1c-2.4,7.4-1.5,8.6,6.2,9.3c0.6,0.1,1.2,0.4,1.7,0.5C531.9,177.4,532,178,532,178.7z M535,137.3
		c8.4,0,16.2,0,24,0c3.2,0,1.7-2,1.2-3.4c-2.6-7.2-5.2-14.3-7.9-21.4c-1.2-3.2-2.4-6.5-4-10.9C543.6,114.1,539.4,125.4,535,137.3z'
        />
        <path
          style={{
            transition: 'fill 450ms ease',
            fill: this.props.colorPrimary,
          }}
          d='M269.4,178.6c-11.3-0.6-22.6-0.3-34-0.1c0.1-2.6,1.5-2.5,2.5-2.5c7.3,0.4,10.9-4,13.3-10
		c5.9-14.5,11.7-29,17.5-43.5c5.4-13.4,10.7-26.9,16.1-40.3c0.7-1.7,1.4-3.4,2.2-5c1.5-2.8,3.2-2.8,4.5,0c2.2,4.8,4.2,9.8,6.2,14.7
		c9.4,23.1,18.6,46.3,28,69.4c1.1,2.8,2.6,5.4,4,8c2.5,4.4,6.2,6.9,11.4,6.7c1,0,2.4-0.3,3.1,1c-3.3,1.9-28.4,2.1-35.2,0.1
		c4.9-1.8,3.2-4.6,2-7.6c-2.9-7.4-5.7-14.9-8.4-22.4c-0.8-2.3-1.8-3.5-4.6-3.5c-8.3,0.2-16.7,0.2-25,0c-2.4,0-3.6,0.7-4.4,2.9
		c-2.2,6.3-4.8,12.4-6.9,18.7c-2.9,8.3-2,9.6,6.5,10.9c0.4,0.1,0.8,0.5,1.2,0.7C269.4,177.5,269.4,178.1,269.4,178.6z M285.8,101.4
		c-4.5,12-8.3,22.5-12.3,33.1c-0.7,1.8-0.8,2.8,1.5,2.8c7.2,0,14.3,0,21.5,0c1.9,0,2.1-0.8,1.5-2.5
		C294,124.1,290.2,113.4,285.8,101.4z'
        />
        <path
          style={{
            transition: 'fill 450ms ease',
            fill: this.props.colorPrimary,
          }}
          d='M248.3,98.3c-1.5-1.4-2-1.6-2.2-2c-2.5-8.1-4.5-9.8-13.2-10.3c-5.7-0.3-11.3-0.1-17-0.3
		c-2.3-0.1-2.8,0.7-2.7,2.8c0.2,26.2-0.5,52.4,0.5,78.6c0.3,7.1,2,8.6,9.1,9c1.6,0.1,3.3-0.3,4.7,1c-2.6,1.8-38.6,2-41.9,0.3
		c0.2-0.7,0.8-0.9,1.5-1.1c8.1-1.4,8.4-1.4,9-9.5c1.1-15.7,0.3-31.4,0.5-47c0.1-10.2-0.1-20.4,0.1-30.5c0-2.8-0.7-3.7-3.6-3.5
		c-7.1,0.4-14.3,0-21.5,0.7c-4.9,0.4-8,2.7-9.8,7.2c-0.4,1-0.3,2.7-2,2.1c-1.4-0.5-1-1.9-0.8-3.1c0.1-0.5,0.3-1,0.4-1.5
		c0.7-4.8,0.2-10.9,3.4-13.7c2.9-2.5,8.5,1,13,0.9c23.2-0.2,46.4,1.2,69.5-0.9c2.8-0.3,3.6,0.1,3.5,2.9
		C248.6,86,248.5,91.7,248.3,98.3z'
        />
        <path
          style={{
            transition: 'fill 450ms ease',
            fill: this.props.colorPrimary,
          }}
          d='M785,97.5c-1.3-11.5-9.8-11.3-17.6-11.6c-5.4-0.2-12.5-2.4-15.8,0.8c-2.6,2.5-0.8,9.8-0.7,14.9
		c0.2,20.5-0.6,41,0.5,61.6c0.1,1.8,0.1,3.7,0.2,5.5c0.4,4.7,2.6,7.4,7.6,7.4c2.1,0,4.1,0.3,6.1,1.6c-14.1,0.8-28.2,0.5-42.7,1.1
		c0.9-2.6,1.9-2.4,2.9-2.6c6.9-0.9,7.7-1.5,8.1-8.2c1.6-26.5,0.3-53,0.7-79.6c0-1.7-0.1-2.8-2.3-2.7c-7.7,0.3-15.3,0-23,0.7
		c-4.8,0.5-7.7,2.7-9.4,6.9c-0.4,1-0.5,2.7-2,2.4c-1.8-0.4-1.1-2.1-0.9-3.4c0.8-4.4,1.7-8.8,2.6-13.2c0.3-1.8,0.7-3.2,3.2-2.4
		c6.8,2.1,13.8,1.9,20.8,1.9c15.4,0,30.7-0.1,46.1,0c4.7,0,9.3-0.3,13.9-1c1.3-0.2,3-0.8,3.1,1.1C786.6,84.9,786.8,91.2,785,97.5z'
        />
        <path
          style={{
            transition: 'fill 450ms ease',
            fill: this.props.colorPrimary,
          }}
          d='M853.3,100c-2.9-1.2-2.2-3.2-2.6-4.7c-1.6-6.1-5.3-10.5-11.4-12.2c-6.5-1.8-13-1.6-18.3,3.4
		c-5,4.7-5.2,12.7-0.5,18.6c3.7,4.6,8.3,8.1,13,11.7c6.4,4.8,12.6,9.9,17.3,16.5c6.8,9.4,8.1,19.8,3.4,30.3
		c-4.8,10.5-14,15.3-25.1,16.4c-8,0.8-16,0.5-23.7-2.3c-2.8-1-4.3-2.6-4.1-5.9c0.4-5.2,0.2-10.3,0.4-15.5c0-1.6,0.1-3.3,1.9-4.6
		c1.9,1.8,1.3,4.4,1.7,6.4c2.9,13.8,17.6,20.6,29.9,13.5c7.3-4.2,9.7-13.2,5.9-21.4c-2.9-6.3-8.3-10.3-13.6-14.4
		c-6.7-5.2-13.4-10.5-18.2-17.7c-12-17.9-2.7-38.5,18.6-41.4c7.6-1.1,14.9-0.2,22.3,1.1c3.2,0.6,4.4,2.1,4,5.5
		C853.7,88.7,853.6,94.3,853.3,100z'
        />
        <path
          style={{
            transition: 'fill 450ms ease',
            fill: this.props.colorPrimary,
          }}
          d='M118.3,339.2c4.3-11.6,8.5-22.7,13-34.8c4.4,12,8.4,23.2,12.7,34.8C135.2,339.2,127.1,339.2,118.3,339.2z'
        />
        <path
          style={{
            transition: 'fill 450ms ease',
            fill: this.props.colorPrimary,
          }}
          d='M442.7,339.4c4.4-11.7,8.5-22.8,13-34.8c2.2,5.8,4.2,11,6,16.1c1.9,5.3,3.8,10.6,5.7,15.9
		c0.6,1.7,1.1,2.9-1.4,2.9C458.4,339.4,450.8,339.4,442.7,339.4z'
        />
      </svg>
    );
  }
}

export default ImageUaaLogo;

ImageUaaLogo.propTypes = {
  colorPrimary: PropTypes.string,
};

ImageUaaLogo.defaultProps = {
  colorPrimary: `#ff0000`,
};
